import React from "react";
import contactStore from "../stores/contactStore";
import { observer } from "mobx-react";
import MassageItem1 from "./MassageItem1";

function DoneMassages() {
  const yes = contactStore.contacts.filter(
    (oneContact) => oneContact.done === true
  );

  const List = yes.map((contact) => (
    <MassageItem1 contact={contact} contactId={contact.id} />
  ));

  const yesLength = List.length;

  return (
    <div>
      <div class="card text-center">
        <div class="card-header" style={{ backgroundColor: "grey" }}>
          <h2> ({yesLength}) الرسائل التي تم الرد عليها - العدد</h2>
        </div>
        <div style={{ backgroundColor: "grey" }}> {List}</div>
      </div>
    </div>
  );
}

export default observer(DoneMassages);
