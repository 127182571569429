import Modal from "react-modal";
import { useState } from "react";
//styles
import { CreateButtonStyled } from "../../styles";
//picture
import productImageStore from "../../stores/productImageStore";

const ProductPicModel = ({ isOpen, closeModal, product }) => {
  const [picture, setpicture] = useState({
    image: "",
  });

  const handleChange = (event) => {
    setpicture({ ...picture, [event.target.name]: event.target.value });
  };
  const handleImg = (event) => {
    setpicture({ ...picture, image: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    closeModal();

    productImageStore.creatProductImage(picture, product);
  };
  return (
    <div>
      <br></br>
      <br></br>

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="picture Modal"
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h4
          style={{
            textAlign: "center",
            width: "100%",
            marginTop: "30px",
            backgroundColor: "grey",
            color: "white",
            fontWeight: "lighter",
            align: "center",
          }}
        >
          اضافه صوره للمنتج {picture.name}{" "}
        </h4>
        <br></br>

        <br></br>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              name="image"
              onChange={handleImg}
              type="file"
              className="form-control"
              align="center"
            />
          </div>
          <CreateButtonStyled>اضافه </CreateButtonStyled>
        </form>
      </Modal>
    </div>
  );
};

export default ProductPicModel;
