import { stack as Menu } from "react-burger-menu";
import React, { useState, useContext } from "react";
import Hamburger from "hamburger-react";
import { Nav, NavDropdown } from "react-bootstrap";

export default function Animatednav() {
  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      right: "50px",
      top: "60px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      top: "0px",
    },
    bmMenu: {
      background: "#2b547e",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {
      display: "block",
      color: "#ffffff",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };
  function showSettings(event) {
    event.preventDefault();
  }

  return (
    <Menu styles={styles} right noOverlay>
      <a id="home" className="menu-item" href="/">
        الصفحة الرئيسية
      </a>
      <a id="about" className="menu-item" href="/aboutus">
        نبذة عن العائلة
      </a>
      <a id="contact" className="menu-item" href="/familytree">
        شجرة العائلة
      </a>
      <NavDropdown title="خدمات" id="basic-nav-dropdown" lang="ar" dir="ltr">
        <NavDropdown.Item href="/lawyer"> محامي</NavDropdown.Item>

        <NavDropdown.Item href="/doctor">أطباء</NavDropdown.Item>
        <NavDropdown.Item href="/mathon">مأذون شرعي</NavDropdown.Item>

        <NavDropdown.Divider />
        <NavDropdown.Item href="/others">خدمات أخرى</NavDropdown.Item>
      </NavDropdown>

      <a id="contact" className="menu-item" href="/dalel">
        دليل الدواوين
      </a>

      <a className="menu-item--small" href="/business">
        الدليل التجاري
      </a>
      <a className="menu-item--small" href="/fazaa">
        فزعة
      </a>
      <a className="menu-item--small" href="sandoq">
        صندوق الشباب
      </a>

      <a className="menu-item--small" href="/alshattishop">
        سوق الشطي
      </a>
      <a className="menu-item--small" href="/album">
        البوم الصور
      </a>
      <a className="menu-item--small" href="/form">
        نموذج جمع بيانات العائلة
      </a>
      <a className="menu-item--small" href="/contactus">
        تواصل معنا
      </a>
    </Menu>
  );
}
