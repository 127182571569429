import React, { Component, useState } from "react";
import "../App.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Modal from "react-bootstrap/Modal";

import Grid from "@material-ui/core/Grid";
import formStore from "../stores/formStore";
import { Redirect, useParams } from "react-router-dom";

const DetailForm = () => {
  const formSlug = useParams().formSlug;

  const form = formStore.forms.find((_form) => _form.slug === formSlug);

  const handleSubmit = (event) => {
    event.preventDefault();

    formStore.updateContact({ ...form, Done: true });
    alert("تم التسجيل");
  };

  return (
    <div className="wrapper">
      <div className="form-wrapper">
        <h1>نموذج جمع بيانات العائلةالشطي</h1>

        <form onSubmit={handleSubmit} noValidate>
          <div className="fullName">
            <label htmlFor="fullName">*الاسم الخماسي</label>
            <input
              placeholder="الاسم الخماسي"
              type="text"
              value={form.name}
              name="name"
              noValidate
              disabled
            />
          </div>
          <div className="fullName">
            <label htmlFor="fullName">(الرقم المدني (اختياري</label>
            <input
              placeholder="الرقم المدني"
              type="text"
              name="civilId"
              value={form.civilId}
              disabled
              noValidate
            />
          </div>
          ]{" "}
          <div className="fullName">
            <label htmlFor="fullName">*تاريخ الميلاد</label>
            <input
              disabled
              format={"DD-MM-YYYY"}
              label="my date"
              inputVariant="outlined"
              placeholder="تاريخ الميلاد"
              type="date"
              name="birthday"
              value={form.birthday}
              format="MM/dd/yyyy"
              noValidate
              id="date"
            />
          </div>
          <div className="address">
            <label htmlFor="address"> *العنوان</label>
            <input
              disabled
              placeholder="العنوان"
              type="text"
              name="address"
              value={form.address}
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">هاتف المنزل</label>
            <input
              disabled
              placeholder="هاتف المنزل"
              value={form.houseNumber}
              type="text"
              name="houseNumber"
              value={form.houseNumber}
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">2 رقم الهاتف</label>
            <input
              placeholder="2 رقم الهاتف"
              disabled
              type="text"
              name="mobile2"
              value={form.mobile2}
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">*رقم الهاتف</label>
            <input
              // className={formErrors.mobile.length > 0 ? "error" : null}
              placeholder="رقم الهاتف"
              type="text"
              disabled
              value={form.mobile}
              name="mobile"
              noValidate
            />
            {/* {formErrors.mobile.length > 0 && (
              <span className="errorMessage">{formErrors.mobile}</span>
            )} */}
          </div>
          <div className="email">
            <label htmlFor="email">الايميل</label>
            <input
              // className={formErrors.email.length > 0 ? "error" : null}
              placeholder="الايميل"
              disabled
              type="email"
              name="email"
              value={form.email}
              noValidate
            />
            {/* {formErrors.email.length < 0 && (
              <span className="errorMessage">{formErrors.email}</span>
            )} */}
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">فاكس</label>
            <input
              placeholder="فاكس"
              disabled
              type="text"
              value={form.fax}
              name="fax"
              noValidate
            />
          </div>
          <h3
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "30px",
              backgroundColor: "#484848	",
              color: "white",
              // fontWeight: "lighter",
            }}
          >
            وسائل التواصل
          </h3>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">تويتر</label>
            <input
              placeholder="تويتر"
              type="text"
              disabled
              value={form.twitter}
              name="twitter"
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">فيسبوك</label>
            <input
              placeholder="فيسبوك"
              value={form.facebook}
              disabled
              type="text"
              name="facebook"
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">انستقرام</label>
            <input
              placeholder=" انستقرام"
              type="text"
              disabled
              name="instagram"
              value={form.instagram}
              noValidate
            />
          </div>
          <h3
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "30px",

              backgroundColor: "#484848	",
              color: "white",
            }}
          >
            العمل
          </h3>
          <div className="mobile">
            <label htmlFor="mobile">الإدارة</label>
            <input
              placeholder="الإدارة"
              value={form.Department}
              type="text"
              disabled
              name="Department"
              noValidate
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">*الوزارة</label>
            <input
              placeholder="الوزارة"
              type="text"
              disabled
              value={form.organisation}
              name="organisation"
              noValidate
            />
          </div>
          <div className="address">
            <label htmlFor="mobile">*المسمى الوظيفي</label>
            <input
              placeholder="المسمى الوظيفي"
              type="text"
              value={form.jobTitle}
              disabled
              name="jobTitle"
              noValidate
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">الشعبة</label>
            <input
              placeholder="الشعبة"
              value={form.sh3ba}
              type="text"
              name="sh3ba"
              disabled
              noValidate
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">القسم</label>
            <input
              placeholder="القسم"
              type="text"
              name="qesm"
              value={form.qesm}
              noValidate
              disabled
            />
          </div>
          <h3
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "30px",
              backgroundColor: "#484848	",
              color: "white",
              // fontWeight: "lighter",
            }}
          >
            العمل التجاري
          </h3>
          <div className="address">
            <label htmlFor="mobile">المجال التجاري</label>
            <input
              placeholder="المجال التجاري"
              type="text"
              disabled
              value={form.commercialField}
              value={form.commercialField}
              name="commercialField"
              noValidate
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">رقم الهاتف</label>
            <input
              placeholder="رقم الهاتف"
              value={form.PhoneNumberOfStore}
              type="text"
              disabled
              name="PhoneNumberOfStore"
              noValidate
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">اسم المشروع</label>
            <input
              placeholder="اسم المشروع"
              value={form.NameOfStore}
              type="text"
              disabled
              name="NameOfStore"
              noValidate
            />
          </div>
          <div className="address">
            <label htmlFor="mobile">حساب الانستقرام</label>
            <input
              placeholder="حساب الانستقرام"
              type="text"
              value={form.instagramBusiness}
              disabled
              name="instagramBusiness"
              noValidate
            />
          </div>
          <div className="address">
            <label htmlFor="mobile">العنوان</label>
            <input
              placeholder="العنوان"
              type="text"
              disabled
              value={form.AdressOfStore}
              name="AdressOfStore"
              noValidate
            />
          </div>
          <h3
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "30px",
              backgroundColor: "#484848	",
              color: "white",
              // fontWeight: "lighter",
            }}
          >
            الأبناء- إناث وذكور
          </h3>
          <div className="address">
            <label htmlFor="mobile">*عدد الابناء</label>
            <input
              placeholder="عدد الابناء"
              value={form.numberofKids}
              type="number"
              name="numberofKids"
              min={1}
              disabled
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">المرحلةالدراسية</label>

            <input
              type="text"
              value={form.levelOfEducationKid1}
              name="levelOfEducationKid1"
              noValidate
              disabled
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">تاريخ *الميلاد-مطلوب</label>

            <input
              placeholder="MM/dd/yyyy"
              type="date"
              value={form.BirthdayOfKid1}
              name="BirthdayOfKid1"
              format="MM/dd/yyyy"
              noValidate
              disabled
              id="date"
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">الاسم- مطلوب</label>

            <input
              type="text"
              name="NameOfKid1"
              noValidate
              placeholder="الابن الاول"
              value={form.NameOfKid1}
              disabled
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="levelOfEducationKid2"
              value={form.levelOfEducationKid2}
              noValidate
              disabled
            />
          </div>
          <div className="mobilegroub">
            <input
              placeholder="MM/dd/yyyy"
              type="date"
              disabled
              name="BirthdayOfKid2"
              value={form.BirthdayOfKid2}
              format="MM/dd/yyyy"
              noValidate
              id="date"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="NameOfKid2"
              value={form.NameOfKid2}
              noValidate
              placeholder="الابن الثاني"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="levelOfEducationKid3"
              value={form.levelOfEducationKid3}
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <input
              disabled
              placeholder="MM/dd/yyyy"
              type="date"
              name="BirthdayOfKid3"
              value={form.BirthdayOfKid3}
              format="MM/dd/yyyy"
              noValidate
              id="date"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="NameOfKid3"
              value={form.NameOfKid3}
              noValidate
              placeholder="الابن الثالث"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="levelOfEducationKid4"
              value={form.levelOfEducationKid4}
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <input
              placeholder="MM/dd/yyyy"
              disabled
              type="date"
              name="BirthdayOfKid4"
              value={form.BirthdayOfKid4}
              format="MM/dd/yyyy"
              noValidate
              id="date"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="NameOfKid4"
              value={form.NameOfKid4}
              noValidate
              placeholder="الابن الرابع"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="levelOfEducationKid5"
              value={form.levelOfEducationKid5}
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <input
              disabled
              placeholder="MM/dd/yyyy"
              type="date"
              name="BirthdayOfKid5"
              value={form.BirthdayOfKid5}
              format="MM/dd/yyyy"
              noValidate
              id="date"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="NameOfKid5"
              value={form.NameOfKid5}
              noValidate
              placeholder="الابن الخامس"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="levelOfEducationKid6"
              value={form.levelOfEducationKid6}
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <input
              placeholder="MM/dd/yyyy"
              disabled
              type="date"
              name="BirthdayOfKid6"
              value={form.BirthdayOfKid6}
              format="MM/dd/yyyy"
              noValidate
              id="date"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="NameOfKid6"
              value={form.NameOfKid6}
              noValidate
              placeholder="الابن السادس"
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              disabled
              name="levelOfEducationKid7"
              value={form.levelOfEducationKid7}
              noValidate
            />
          </div>
          <div className="mobilegroub">
            <input
              disabled
              placeholder="MM/dd/yyyy"
              type="date"
              name="BirthdayOfKid7"
              value={form.BirthdayOfKid7}
              format="MM/dd/yyyy"
              noValidate
              id="date"
            />
          </div>
          <div className="mobilegroub">
            <input
              disabled
              type="text"
              name="NameOfKid7"
              value={form.NameOfKid7}
              noValidate
              placeholder="الابن السابع"
            />
          </div>
          {form.Done === false && (
            <div className="createAccount">
              <button onClick={handleSubmit}> تم الاطلاع</button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
export default DetailForm;
