import { observer } from "mobx-react";
import "../gallery.css";
import React from "react";
import familyTree from "../familyTree.JPG";
import AboutUsPic from "../AboutUsPic.JPG";
import Business from "../Business.JPG";

function Redirect3Pic() {
  return (
    <div className="main-gallery">
      <div class="image-container">
        <a id="contact" className="menu-item" href="/business">
          <div
            class="tile"
            style={{
              backgroundImage: `url(${Business})`,
            }}
          >
            <div class="image-title">
              <h5>الدليل التجاري</h5>
            </div>
          </div>
        </a>
        <a id="contact" className="menu-item" href="/aboutus">
          <div
            class="tile"
            style={{
              backgroundImage: `url(${AboutUsPic})`,
            }}
          >
            <div class="image-title">
              <h5>نبذة عن العائلة</h5>
            </div>
          </div>
        </a>
        <a id="contact" className="menu-item" href="/familytree">
          <div
            class="tile"
            style={{
              backgroundImage: `url(${familyTree})`,
            }}
          >
            <div class="image-title">
              <h5>شجرة العائلة</h5>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default observer(Redirect3Pic);
