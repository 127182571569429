import React from "react";
import contactStore from "../stores/contactStore";
import { observer } from "mobx-react";
import { Link} from "react-router-dom";

import MassagesItem from "./MassageItem";
function Massages() {
  const Not = contactStore.contacts.filter(
    (oneContact) => oneContact.done === false
  );

  const NotList = Not.map((contact) => (
    <MassagesItem contact={contact} contactId={contact.id} />
  ));

  const NotLength = NotList.length;

  return (
    <div>
      <h1>{"   "}</h1>
      <Link to="donemassages">
        <div align="center">
          <button type="button" class="btn btn-primary">
            الرسائل المقروءة{" "}
          </button>
        </div>
      </Link>
      <h1>{"   "}</h1>

      <div class="card text-center">
        <div class="card-header" style={{ backgroundColor: "#99ccff" }}>
          {NotLength} الرسائل التي لم يتم الرد عليها - العدد
        </div>
        {NotList}
      </div>
    </div>
  );
}

export default observer(Massages);
