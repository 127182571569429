import LoadingScreen from "react-loading-screen";
const FullLoadingScreen = () => {
  return (
    <LoadingScreen
      loading={true}
      spinnerColor="grey"
      textColor="black"
    ></LoadingScreen>
  );
};

export default FullLoadingScreen;
