import React from "react";
import { observer } from "mobx-react";
import SmallestLogo from "../SmallestLogo.jpg";

import { Nav, NavDropdown } from "react-bootstrap";
import Container from "@material-ui/core/Container";

import { FiLogOut } from "react-icons/fi";
import { Logo } from "../styles";
import formStore from "../stores/formStore";

import authStore from "../stores/authStore";
import Navbar from "react-bootstrap/Navbar";
import contactStore from "../stores/contactStore";
import sandoqStore from "../stores/sandoqStore";

import MassagesItem from "./MassageItem";
import UnMemberItem from "./UnMemberItem";
import Animatednav from "./Animatednav";

const NavBarBootstrap = () => {
  const Not = contactStore.contacts.filter(
    (oneContact) => oneContact.done === false
  );

  const NotList = Not.map((contact) => (
    <MassagesItem contact={contact} contactId={contact.id} />
  ));

  const NotLength = NotList.length;

  const Not2 = sandoqStore.sandoqs.filter(
    (oneSandoq) => oneSandoq.doneContact === false
  );

  const UnmemberList = Not2.map((member) => (
    <UnMemberItem member={member} memberId={member.id} />
  ));

  const UnmebMerLength = UnmemberList.length;
  const Unread = formStore.forms.filter((form) => form.Done === false);
  const FormLength = Unread.length;
  console.log("hhhhh");
  {
    authStore.user && console.log(authStore.user.username);
  }
  return (
    <div class="header-secondnav sticky-top">
      <Navbar
        bg="white"
        expand="lg"
        sticky="top"
        position="fixed"
        align="center"
        style={{ display: "block" }}
        class="header-secondnav sticky-top"
      >
        <div align="center" alignItems="center">
          <Navbar.Brand
            alignItems="center"
            class="header-secondnav sticky-top"
            style={{ marginRight: 0 }}
          >
            <Animatednav />
            <Logo
              to="/"
              className="navbar-brand"
              align="center"
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={SmallestLogo}
                width="1090px"
                height="100px"
                class="center"
                position="absolute"
                align="center"
                display="block"
                alt="img"
              />
            </Logo>
          </Navbar.Brand>
        </div>
        <div>
          <Container component="main" maxWidth="l" align="center">
            {authStore.user && (
              <Nav.Link href="/massages" alignItems="center">
                الرسائل {""}
                {NotLength}
              </Nav.Link>
            )}

            {authStore.user && authStore.user.username === "shattamimK" && (
              <Nav.Link href="/members" alignItems="center">
                اعضاء صندوق الشطي {UnmebMerLength}
              </Nav.Link>
            )}

            {authStore.user && authStore.user.username === "shattamimK" && (
              <Nav.Link href="/allforms" alignItems="center">
                بيانات العائلة {FormLength}
              </Nav.Link>
            )}

            {authStore.user ? (
              <>
                <div class="navbar-inner">
                  {""} مرحباً {authStore.user.username}
                  {""}
                  <FiLogOut
                    onClick={authStore.signout}
                    size="2em"
                    color="black"
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </Container>
        </div>
      </Navbar>
    </div>
  );
};

export default observer(NavBarBootstrap);
