import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { observer } from "mobx-react";
import imageSliderStore from "../../stores/imageSliderStore";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const DoctorModel = () => {
  const classes = useStyles();
  let history = useHistory();

  const [imageSlider, setimageSlider] = useState({
    image: "",
    caption: "",
    doctor: true,
  });

  const handleImg = (event) => {
    setimageSlider({ ...imageSlider, image: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    console.log(handleSubmit);
    event.preventDefault();
    imageSliderStore.creatImageSlider(imageSlider);
    history.push("/doctor");
  };

  return (
    <Container flexDirection="rtl" component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          <h3> إضافة صورة</h3>
        </Typography>
        <form className={classes.form} noValidate>
          <label> صورة </label>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            name="image"
            onChange={handleImg}
            type="file"
            flexDirection="rtl"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            اضافة
          </Button>

          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default observer(DoctorModel);
