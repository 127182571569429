import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import UnMemberItem from "./UnMemberItem";
import sandoqStore from "../stores/sandoqStore";
function Members() {
  const Not = sandoqStore.sandoqs.filter(
    (oneSandoq) => oneSandoq.doneContact === false
  );

  const UnmemberList = Not.map((member) => (
    <UnMemberItem member={member} memberId={member.id} />
  ));

  const UnmebMerLength = UnmemberList.length;

  return (
    <div>
      <h1>{"   "}</h1>
      <Link to="contacedmembers">
        <div align="center">
          <button type="button" class="btn btn-primary">
            اعضاء صندوق الشطي{" "}
          </button>
        </div>
      </Link>
      <h1>{"   "}</h1>
      <div class="card text-center">
        <div class="card-header" style={{ backgroundColor: "#99ccff" }}>
          {UnmebMerLength} طلب عضوية / العدد
        </div>
        {UnmemberList}
      </div>
    </div>
  );
}

export default observer(Members);
