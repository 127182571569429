import { makeObservable, observable, action } from "mobx";

import instance from "./instance";

class ContactStore {
  contacts = [];
  loading = true;

  constructor() {
    makeObservable(this, {
      contacts: observable,
      loading: observable,
      creatContact: action,
      deleteContact: action,
      updateContact: action,
      fetchContacts: action,
    });
  }

  getcontactId = (contactId) =>
    this.contacts.find((contact) => contact.id === contactId);

  fetchContacts = async () => {
    try {
      const response = await instance.get("/contact");
      this.contacts = response.data;
      this.loading = false;
    } catch (error) {
      console.error("Contact -> fetchContacts -> error", error);
    }
  };

  creatContact = async (newContact, cb) => {
    try {
      const response = await instance.post("/contact", newContact);
      this.contacts.push(response.data);
      cb();
    } catch (error) {
      console.error(console.error);
    }
  };

  deleteContact = async (contactId) => {
    try {
      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");

      if (r == true) {
        await instance.delete(`/contact/${contactId}`);
        this.contacts = this.contacts.filter(
          (contact) => contact.id !== contactId
        );
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.log("GoalsStore -> deleteContact -> error", error);
    }
  };

  updateContact = async (updatecontact, cb) => {
    try {
      const formData = new FormData();
      for (const key in updatecontact) formData.append(key, updatecontact[key]);
      await instance.put(`/contact/${updatecontact.id}`, updatecontact);

      const contact = this.contacts.find(
        (contact) => contact.id === updatecontact.id
      );
      for (const key in contact) contact[key] = updatecontact[key];
      cb();
    } catch (error) {
      console.log("Contactstore -> updatecontact -> error", error);
    }
  };
}

const contactStore = new ContactStore();
contactStore.fetchContacts();
export default contactStore;
