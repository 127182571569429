import { makeAutoObservable } from "mobx";
import instance from "./instance";

class DewanStore {
  dewans = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getdewanId = (dewanId) => this.dewans.find((dewan) => dewan.id === dewanId);

  fetchDewans = async () => {
    try {
      const response = await instance.get("/dewan");
      this.dewans = response.data;
      this.loading = false;
    } catch (error) {
      console.error("Dewan -> fetchDewans -> error", error);
    }
  };

  creatDewan = async (newDewan, cb) => {
    try {
      const formData = new FormData();
      for (const key in newDewan) formData.append(key, newDewan[key]);

      const response = await instance.post("/dewan", formData);
      this.dewans.push(response.data);
      cb();
    } catch (error) {
      console.error(console.error);
    }
  };
  updateDewan = async (updatedDewan, cb) => {
    try {
      const formData = new FormData();

      for (const key in updatedDewan) formData.append(key, updatedDewan[key]);
      console.log(updatedDewan.id);
      await instance.put(`/dewan/${updatedDewan.id}`, formData);
      console.log({ dewans: this.dewans });
      const dewan = this.dewans.find((dewan) => dewan.id === updatedDewan.id);
      console.log({ dewan, updatedDewan });

      cb();
      for (const key in updatedDewan) dewan[key] = updatedDewan[key];
      dewan.image = URL.createObjectURL(updatedDewan.image);
      console.log({ dewan });
    } catch (error) {
      console.error(
        "🚀 ~ file: dewanStore.js ~ line 35 ~ DewanStore ~ updateDewan ~ error",
        error
      );
    }
  };
  deleteDewan = async (dewanId) => {
    try {
      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");
      if (r == true) {
        await instance.delete(`/dewan/${dewanId}`);

        this.dewans = this.dewans.filter((dewan) => dewan.id !== dewanId);
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.log("GoalsStore -> deleteDewan -> error", error);
    }
  };
}

const dewanStore = new DewanStore();
dewanStore.fetchDewans();
export default dewanStore;
