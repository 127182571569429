import { makeAutoObservable } from "mobx";
import slugify from "react-slugify";
import instance from "./instance";

class PirctureStore {
  pictures = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getPirctureId = (pirctureId) =>
    this.pictures.find((pircture) => pircture.id === pirctureId);

  fetchPirctures = async () => {
    try {
      const response = await instance.get("/pictures");
      this.pictures = response.data;
      this.loading = false;
    } catch (error) {
      console.error("ProductStore -> fetchProducts -> error", error);
    }
  };

  creatPircture = async (newPircture, catalouge) => {
    try {
      const formData = new FormData();

      for (const key in newPircture) formData.append(key, newPircture[key]);
      const response = await instance.post(
        `/catalouge/${catalouge.id}/pictures`,

        formData
      );
      this.pictures.push(response.data);
      catalouge.pictures.push({ id: response.data.id });
    } catch (error) {
      console.error(console.error);
    }
  };

  deletePircture = async (picture, catalouge) => {
    try {
      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");
      if (r == true) {
        await instance.delete(`/pictures/${picture.id}`);

        const newpictures = catalouge.products.filter(
          (_picture) => _picture.id !== picture.id
        );
        catalouge.pictures = newpictures;
        this.pictures = this.pictures.filter(
          (_picture) => _picture.id !== picture.id
        );
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.error(
        "🚀 ~ file: pictureStore.js ~ line 53 ~ pictureStore ~ deletepicture ~ error",
        error
      );
    }
  };
}
const pirctureStore = new PirctureStore();
pirctureStore.fetchPirctures();
export default pirctureStore;
