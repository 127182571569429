import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Carousel } from "react-responsive-carousel";
import AddPictureBtn from "./buttons/AddPictureBtn";

//styles
import {
  DeleteButtonStyled,
  DetailWrapper,
  UpdateButtonStyled,
} from "../styles";
//store
import authStore from "../stores/authStore";
import albumStore from "../stores/albumStore";
import { Link } from "react-router-dom";
import pictureStore from "../stores/pictureStore";
import {
  ArrowLeft,
  ArrowLeftOutlined,
  ArrowRightAlt,
} from "@material-ui/icons";

const DetailALbum = () => {
  const albumSlug = useParams().albumSlug;

  const album = albumStore.albums.find(
    (_album) => _album.slugcreator === albumSlug
  );

  if (!album) return <Redirect to="/album" />;

  const ImagesFromAblumStore = album.pictures.map((picture) =>
    pictureStore.getPirctureId(picture.id)
  );

  const ImageList = ImagesFromAblumStore.map((picture) => (
    <div>
      <img src={picture.image} />
      <div>
        {authStore.user ? (
          <div>
            <br></br>
            <button
              class="btn btn-danger"
              onClick={() => pictureStore.deletePircture(picture, album)}
            >
              حذف
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  ));

  return (
    <div>
      {authStore.user ? (
        <div align="center">
          <br></br>
          <AddPictureBtn album={album} />
        </div>
      ) : (
        ""
      )}
      <Link to="/album">
        <div>
          <br></br>{" "}
          <h5>
            <ArrowLeftOutlined /> {""}الرجوع للصفحة السابقة {""}
          </h5>
        </div>
      </Link>

      <DetailWrapper>
        <Carousel autoPlay infiniteLoop={true} showIndicators={false}>
          {ImageList}
        </Carousel>
      </DetailWrapper>
    </div>
  );
};

export default observer(DetailALbum);
