import { makeAutoObservable } from "mobx";

import instance from "./instance";

class InformationStore {
  informations = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }
  fetchInformations = async () => {
    try {
      const response = await instance.get("/information");
      this.informations = response.data;
      this.loading = false;
    } catch (error) {
      console.error("InformationStore -> fetchInformations -> error", error);
    }
  };

  count = async () => {
    const inc = localStorage.getItem("counted") ? 0 : 1;

    try {
      const response = await instance.get(`/count?inc=${inc}`);
      console.log(response.data, { inc });
      localStorage.setItem("counted", true);
    } catch (error) {
      console.error("Business -> fetchBusinesss -> error", error);
    }
  };

  updateInformations = async (updateInformations, cb) => {
    try {
      const formData = new FormData();
      for (const key in updateInformations)
        formData.append(key, updateInformations[key]);
      await instance.put(
        `/information/${updateInformations.id}`,
        updateInformations
      );
      cb();
      const information = this.information.find(
        (information) => information.id === updateInformations.id
      );
      for (const key in information) information[key] = updateInformations[key];
    } catch (error) {
      console.log("InformationStore -> updateInformations -> error", error);
    }
  };
}

const informationStore = new InformationStore();
informationStore.count().then(() => {
  informationStore.fetchInformations();
});
export default informationStore;
