import Modal from "react-modal";
import { useState } from "react";

//styles
import { CreateButtonStyled } from "../../styles";

import imageSliderStore from "../../stores/imageSliderStore";

const UpdateIimagesModel = ({ isOpen, closeModal, oldimage }) => {
  const [pic, picPic] = useState(oldimage);

  const handleChange = (event) => {
    picPic({ ...pic, [event.target.name]: event.target.value });
  };
  const handleImg = (event) => {
    picPic({ ...pic, image: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    closeModal();
    imageSliderStore.updateImageSlider(pic);
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel="pic Modal">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h3 align="right"> تعديل </h3>
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <div className="col-12">
            <h3 align="right">الصورة</h3>
            <input
              name="image"
              onChange={handleImg}
              type="file"
              className="form-control"
            />
          </div>
          <h3 align="right">(تعليق أو (رابط قوقل ماب-للعزاء والاعراس</h3>
          <input
            value={pic.caption}
            name="caption"
            onChange={handleChange}
            type="text"
            className="form-control"
          />

          <h3 align="right">العنوان فقط للعزاء و الاعراس</h3>
          <input
            value={pic.adresss}
            name="adresss"
            onChange={handleChange}
            type="text"
            className="form-control"
          />
        </div>

        <p></p>

        <CreateButtonStyled>تعديل</CreateButtonStyled>
      </form>
    </Modal>
  );
};

export default UpdateIimagesModel;
