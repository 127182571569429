import { makeAutoObservable } from "mobx";
import slugify from "react-slugify";
import instance from "./instance";

class ProductStore {
  products = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getProductId = (productId) =>
    this.products.find((product) => product.id === productId);

  fetchProducts = async () => {
    try {
      const response = await instance.get("/products");
      this.products = response.data;
      this.loading = false;
    } catch (error) {
      console.error("ProductStore -> fetchProducts -> error", error);
    }
  };

  updateProduct = async (updatedProduct) => {
    try {
      const formData = new FormData();

      for (const key in updatedProduct)
        formData.append(key, updatedProduct[key]);
      console.log(updatedProduct.id);
      await instance.put(`/products/${updatedProduct.id}`, formData);

      const product = this.products.find(
        (product) => product.id === updatedProduct.id
      );
      for (const key in updatedProduct) product[key] = updatedProduct[key];
      product.img = URL.createObjectURL(updatedProduct.img);
    } catch (error) {
      console.error(
        "🚀 ~ file: productStore.js ~ line 35 ~ ProductStore ~ updateProduct ~ error",
        error
      );
    }
  };

  creatProduct = async (newProduct, cb) => {
    try {
      const formData = new FormData();

      for (const key in newProduct) formData.append(key, newProduct[key]);
      const response = await instance.post("/products", formData);

      this.products.push(response.data);
      cb();
    } catch (error) {
      console.error(console.error);
    }
  };

  deleteProduct = async (productId, store) => {
    try {
      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");
      if (r == true) {
        await instance.delete(`/products/${productId}`);

        const newProducts = store.products.filter(
          (product) => product.id !== productId
        );
        store.products = newProducts;
        this.products = this.products.filter(
          (product) => product.id !== productId
        );
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.error(
        "🚀 ~ file: productStore.js ~ line 53 ~ ProductStore ~ deleteProduct ~ error",
        error
      );
    }
  };
}
const productStore = new ProductStore();
productStore.fetchProducts();
export default productStore;
