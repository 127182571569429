import React from "react";
import { observer } from "mobx-react";
import sandoqStore from "../stores/sandoqStore";

function UnMemberItem({ member }) {
  const HandleSubmet = () => {
    sandoqStore.updateSandoq({ ...member, doneContact: true });
    alert("تم التسجيل");
  };

  return (
    <div>
      <div class="card text-center">
        <h2 style={{ color: "#004b49" }}>:الاسم</h2>
        <p class="card-text"> {member.name}</p>

        <h2 style={{ color: "#004b49" }}>:رقم الهاتف</h2>
        <p class="card-text"> {member.phonenumber} </p>
        <h2 style={{ color: "#004b49" }}>:العنوان</h2>
        <p class="card-text"> {member.address} </p>
        <div class="middle" align="center">
          <button
            class="btn btn-danger"
            align="center"
            onClick={() => sandoqStore.deleteSandoq(member.id)}
          >
            حذف{" "}
          </button>
        </div>
        {""}
        <button class="btn btn-primary" onClick={HandleSubmet}>
          تم الرد{" "}
        </button>
      </div>
    </div>
  );
}

export default observer(UnMemberItem);
