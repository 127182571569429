import businessStore from "../stores/businessStore";
import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UpdateBusiness = ({ oldBusiness }) => {
  oldBusiness = oldBusiness || JSON.parse(localStorage.getItem("business"));

  const classes = useStyles();

  const [business, setBusiness] = React.useState(oldBusiness);

  const handleChange = (event) => {
    console.log(business);
    setBusiness({ ...business, [event.target.name]: event.target.value });
  };

  const handleFood = () => {
    setBusiness({
      ...business,
      food: true,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: false,
      others: false,
    });
  };
  const handleDessert = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: true,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: false,
      others: false,
    });
  };

  const handleDrink = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: true,
      landury: false,
      cars: false,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: false,
      others: false,
    });
  };

  const handlelandury = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: true,
      cars: false,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: false,
      others: false,
    });
  };

  const handlecars = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: true,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: false,
      others: false,
    });
  };

  const handlehealth = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: true,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: false,
      others: false,
    });
  };

  const handlehandmade = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: false,
      handmade: true,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: false,
      others: false,
    });
  };

  const handleperfume = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: false,
      handmade: false,
      perfume: true,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: false,
      others: false,
    });
  };

  const handlemanclothes = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: true,
      womenclothes: false,
      salons: false,
      photos: false,
      others: false,
    });
  };

  const handlewomenclothes = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: true,
      salons: false,
      photos: false,
      others: false,
    });
  };

  const handlesalons = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: true,
      photos: false,
      others: false,
    });
  };

  const handlephotos = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: true,
      others: false,
    });
  };

  const handleothers = () => {
    setBusiness({
      ...business,
      food: false,
      dessert: false,
      coffeAndDrinks: false,
      landury: false,
      cars: false,
      health: false,
      handmade: false,
      perfume: false,
      manclothes: false,
      womenclothes: false,
      salons: false,
      photos: false,
      others: true,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    businessStore.updateBusiness(business, () => {
      window.location = "/business";
    });
  };

  return (
    <Container flexDirection="rtl" component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          تعديل
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="اسم الحساب"
            name="instagramAcount"
            value={business.instagramAcount}
            type="text"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="رابط حساب الانستقرام"
            value={business.instagramLink}
            name="instagramLink"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={business.owner}
            name="owner"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="نوع المشروع"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="العنوان"
            value={business.address}
            name="address"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            label="رقم الهاتف"
            value={business.phoneNumber}
            name="phoneNumber"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />
          <div>
            <input
              type="radio"
              value="food"
              name="Radio1"
              onChange={handleFood}
              checked={business.food}
            />
            مأكولات/مطاعم
          </div>
          <div>
            <input
              type="radio"
              value="dessert"
              name="Radio1"
              onChange={handleDessert}
              checked={business.dessert}
            />
            حلويات
          </div>

          <div>
            <input
              type="radio"
              value="coffeAndDrinks"
              name="Radio1"
              onChange={handleDrink}
              checked={business.coffeAndDrinks}
            />
            مشروبات/قهوة{" "}
          </div>

          <div>
            <input
              type="radio"
              value="landury"
              name="Radio1"
              onChange={handlelandury}
              checked={business.landury}
            />
            مصبغة{" "}
          </div>

          <div>
            <input
              type="radio"
              value="cars"
              name="Radio1"
              onChange={handlecars}
              checked={business.cars}
            />
            غسيل سيارات/كراجات{" "}
          </div>

          <div>
            <input
              type="radio"
              value="health"
              name="Radio1"
              onChange={handlehealth}
              checked={business.health}
            />
            صحة/رياضة{" "}
          </div>

          <div>
            <input
              type="radio"
              value="handmade"
              name="Radio1"
              onChange={handlehandmade}
              checked={business.handmade}
            />
            اعمال يدوية/حرفية{" "}
          </div>
          <div>
            <input
              type="radio"
              value="perfume"
              name="Radio1"
              onChange={handleperfume}
              checked={business.perfume}
            />
            عطور وبخور{" "}
          </div>

          <div>
            <input
              type="radio"
              value="manclothes"
              name="Radio1"
              onChange={handlemanclothes}
              checked={business.manclothes}
            />
            ملابس رجالية/مسابيح{" "}
          </div>

          <div>
            <input
              type="radio"
              value="womenclothes"
              name="Radio1"
              onChange={handlewomenclothes}
              checked={business.womenclothes}
            />
            ملابس نسائية/اكسسوارات{" "}
          </div>

          <div>
            <input
              type="radio"
              value="salons"
              name="Radio1"
              checked={business.salons}
              onChange={handlesalons}
            />
            صالونات نسائية{" "}
          </div>

          <div>
            <input
              type="radio"
              value="photos"
              name="Radio1"
              onChange={handlephotos}
              checked={business.photos}
            />
            مصورين/مصممين/شركات انتاج{" "}
          </div>

          <div>
            <input
              type="radio"
              value="others"
              name="Radio1"
              onChange={handleothers}
              checked={business.others}
            />
            اخرى{" "}
          </div>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            تعديل
          </Button>
          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default observer(UpdateBusiness);
