import faz3aStore from "../stores/faz3aStore";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const AddFaz3a = () => {
  const classes = useStyles();

  const [faz3a, setFaz3a] = useState({
    image: "",
    name: "",
    jam3ya: "",
    description: "",
    link: "",
    tittle: "",
  });

  const handleChange = (event) => {
    console.log(faz3a);
    setFaz3a({ ...faz3a, [event.target.name]: event.target.value });
  };

  const handleImg = (event) => {
    setFaz3a({ ...faz3a, image: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    console.log(faz3a);
    console.log(handleSubmit);
    event.preventDefault();

    faz3aStore.creatFaz3a(faz3a, () => {
      window.location = "/fazaa";
    });
  };

  return (
    <Container flexDirection="rtl" component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          اضافه مشروع
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={faz3a.tittle}
            name="tittle"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="عنوان الشروع"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={faz3a.jam3ya}
            name="jam3ya"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="الجمعية الخيرية"
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            label="وصف المشروع"
            value={faz3a.description}
            name="description"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            label="رابط الموقع "
            value={faz3a.link}
            name="link"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />

          <label> صورة عن المشروع</label>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            name="image"
            onChange={handleImg}
            type="file"
            flexDirection="rtl"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            اضافة
          </Button>

          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default observer(AddFaz3a);
