import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { observer } from "mobx-react";

import informationStore from "../stores/informationStore";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const EditAboutUs = ({ information }) => {
  information = information || JSON.parse(localStorage.getItem("information"));
  console.log({
    information,
    storage: JSON.parse(localStorage.getItem("information")),
  });
  const classes = useStyles();

  const [info, setInfo] = React.useState(information);

  const handleChange = (event) => {
    console.log(info);
    setInfo({ ...info, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    console.log(info);
    event.preventDefault();

    informationStore.updateInformations(info, () => {
      window.location = "/aboutus";
    });
  };

  return (
    <Container flexDirection="rtl" component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          تعديل بيانات الصفحة
        </Typography>
        (يمكنك ترك الخانة فارغه)
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="1 فقرة"
            name="Tittle"
            value={info.Tittle}
            type="text"
            onChange={handleChange}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="فقرة 2"
            name="aboutUsLong"
            value={info.aboutUsLong}
            type="text"
            onChange={handleChange}
            multiline
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="فقرة 3 "
            name="Tittle2"
            value={info.Tittle2}
            type="text"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            required
            fullWidth
            autoFocus
            label="فقرة4"
            margin="normal"
            name="aboutUsLong2"
            value={info.aboutUsLong2}
            type="text"
            onChange={handleChange}
            multiline
          />
          <TextField
            variant="outlined"
            required
            fullWidth
            autoFocus
            label=" فقرة 5 "
            margin="normal"
            name="Tittle3"
            value={info.Tittle3}
            type="text"
            onChange={handleChange}
          />

          <TextField
            variant="outlined"
            required
            fullWidth
            autoFocus
            label="فقرة 6"
            margin="normal"
            name="aboutUsLong3"
            value={info.aboutUsLong3}
            type="text"
            onChange={handleChange}
            multiline
          />
          <TextField
            variant="outlined"
            required
            fullWidth
            autoFocus
            label=" فقرة 7"
            margin="normal"
            name="Tittle4s"
            value={info.Tittle4s}
            type="text"
            onChange={handleChange}
          />

          <TextField
            variant="outlined"
            required
            fullWidth
            autoFocus
            label="فقرة 8"
            margin="normal"
            name="aboutUsLong4"
            value={info.aboutUsLong4}
            type="text"
            onChange={handleChange}
            multiline
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            تعديل
          </Button>
          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default observer(EditAboutUs);
