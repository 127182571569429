import { makeAutoObservable } from "mobx";
import instance from "./instance";

class ImageSliderStore {
  imageSliders = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getimageSliderId = (imageSliderId) =>
    this.imageSliders.find((imageSlider) => imageSlider.id === imageSliderId);

  fetchImageSliders = async () => {
    try {
      const response = await instance.get("/imageSlider");
      this.imageSliders = response.data;
      this.loading = false;
    } catch (error) {
      console.error("ImageSlider -> fetchImageSliders -> error", error);
    }
  };

  creatImageSlider = async (newImageSlider, cb) => {
    try {
      const formData = new FormData();
      for (const key in newImageSlider)
        formData.append(key, newImageSlider[key]);

      const response = await instance.post("/imageSlider", formData);
      this.imageSliders.push(response.data);
      cb();
    } catch (error) {
      console.error(console.error);
    }
  };

  updateImageSlider = async (updatedImageSlider) => {
    try {
      const formData = new FormData();

      for (const key in updatedImageSlider)
        formData.append(key, updatedImageSlider[key]);
      console.log(updatedImageSlider.id);
      await instance.put(`/imageSlider/${updatedImageSlider.id}`, formData);
      const imageSlider = this.imageSliders.find(
        (imageSlider) => imageSlider.id === updatedImageSlider.id
      );
      console.log({ imageSlider, updatedImageSlider });

      for (const key in updatedImageSlider)
        imageSlider[key] = updatedImageSlider[key];
      imageSlider.image = URL.createObjectURL(updatedImageSlider.image);
    } catch (error) {
      console.error(
        "🚀 ~ file: dewanStore.js ~ line 35 ~ DewanStore ~ updateDewan ~ error",
        error
      );
    }
  };

  deleteImageSlider = async (imageSliderId) => {
    try {
      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");
      if (r == true) {
        await instance.delete(`/imageSlider/${imageSliderId}`);

        this.imageSliders = this.imageSliders.filter(
          (imageSlider) => imageSlider.id !== imageSliderId
        );
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.log("GoalsStore -> deleteImageSlider -> error", error);
    }
  };
}

const imageSliderStore = new ImageSliderStore();
imageSliderStore.fetchImageSliders();
export default imageSliderStore;
