import React from "react";
import { observer } from "mobx-react";

import { TotalPicStyle } from "../styles";
import informationStore from "../stores/informationStore";
function Player1({ information }) {
  return (
    <>
      {information.vedioLink !== "" && (
        <div align="center">
          <div class="col-md-12">
            <TotalPicStyle>
              {" "}
              <iframe
                class="responsive-iframe"
                width="560"
                height="315"
                align="center"
                src={information.vedioLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </TotalPicStyle>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(Player1);
