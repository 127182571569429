import { makeAutoObservable } from "mobx";
import slugify from "react-slugify";
import instance from "./instance";

class ProductImageStore {
  productImages = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getProductImageId = (productImageId) =>
    this.productImages.find(
      (productImage) => productImage.id === productImageId
    );

  fetchProductImages = async () => {
    try {
      const response = await instance.get("/productImages");
      this.productImages = response.data;
      this.loading = false;
    } catch (error) {
      console.error("ProductStore -> fetchProducts -> error", error);
    }
  };

  creatProductImage = async (newProductImage, product) => {
    try {
      const formData = new FormData();

      for (const key in newProductImage)
        formData.append(key, newProductImage[key]);
      const response = await instance.post(
        `/products/${product.id}/productImages`,
        formData
      );
      this.productImages.push(response.data);
      product.productimages.push({ id: response.data.id });
    } catch (error) {
      console.error(console.error);
    }
  };

  deleteProductImage = async (productimages, product) => {
    try {
      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");
      if (r == true) {
        await instance.delete(`/productImages/${productimages.id}`);

        const newpictures = product.productimages.filter(
          (productimages) => productimages.id !== productimages.id
        );
        product.productimages = newpictures;
        this.productimages = this.productimages.filter(
          (_picture) => _picture.id !== productimages.id
        );
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.error(
        "🚀 ~ file: pictureStore.js ~ line 53 ~ pictureStore ~ deletepicture ~ error",
        error
      );
    }
  };
}
const productImageStore = new ProductImageStore();
productImageStore.fetchProductImages();
export default productImageStore;
