import React from "react";
import { observer } from "mobx-react";

function MassageItem1({ contact }) {
  return (
    <div style={{ backgroundColor: "grey" }}>
      <div class="card text-center">
        <h4 style={{ color: "#004b49" }}>:الاسم</h4>
        <p class="card-text"> {contact.name}</p>
        <h4 style={{ color: "#004b49" }}>:الايميل</h4>
        <p class="card-text"> {contact.email} </p>
        <h4 style={{ color: "#004b49" }}>:رقم الهاتف</h4>
        <p class="card-text"> {contact.phonenumber} </p>
        <h4 style={{ color: "#004b49" }}>:الرسالة</h4>
        <p class="card-text"> {contact.text} </p>
      </div>
    </div>
  );
}

export default observer(MassageItem1);
