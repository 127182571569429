import React from "react";

import "./App.css";

import NavBarBootstrap from "./component/NavBarBootstrap";
import BottomNavBar from "./component/BottomNavBar";
import { observer } from "mobx-react";

import Routes from "./component/Routes";

import informationStore from "./stores/informationStore";

import businessStore from "./stores/businessStore";
import contactStore from "./stores/contactStore";
import dewanStore from "./stores/dewanStore";
import faz3aStore from "./stores/faz3aStore";
import imageSliderStore from "./stores/imageSliderStore";
import sandoqStore from "./stores/sandoqStore";
import authStore from "./stores/authStore";

import { ThemeProvider } from "styled-components";
import BackgroundNoName from "./BackgroundNoName.JPG";
import FullLoadingScreen from "./component/FullLoadingScreen";
import productImageStore from "./stores/productImageStore";
import productStore from "./stores/productStore";
import albumStore from "./stores/albumStore";
import pirctureStore from "./stores/pictureStore";

function App() {
  return (
    <div>
      <div class="header-secondnav sticky-top">
        <NavBarBootstrap
          sticky="top"
          position="fixed"
          width="90%"
          align="center"
        />
      </div>

      {informationStore.loading ||
      businessStore.loading ||
      contactStore.loading ||
      dewanStore.loading ||
      faz3aStore.loading ||
      imageSliderStore.loading ||
      sandoqStore.loading ||
      productImageStore.loading ||
      productStore.loading ||
      albumStore.loading ||
      pirctureStore.loading ? (
        <FullLoadingScreen />
      ) : (
        <Routes />
      )}
      <div>
        <BottomNavBar position="fixed" class="fixed-bottom" />
      </div>
    </div>
  );
}
export default observer(App);
