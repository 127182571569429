import { makeAutoObservable } from "mobx";
import instance from "./instance";

class SandoqStore {
  sandoqs = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getsandoqId = (sandoqId) =>
    this.sandoqs.find((sandoq) => sandoq.id === sandoqId);

  fetchSandoqs = async () => {
    try {
      const response = await instance.get("/sandoq");
      this.sandoqs = response.data;
      this.loading = false;
    } catch (error) {
      console.error("Sandoq -> fetchSandoqs -> error", error);
    }
  };

  creatSandoq = async (newSandoq, cb) => {
    try {
      const response = await instance.post("/sandoq", newSandoq);
      this.sandoqs.push(response.data);
      cb();
    } catch (error) {
      console.error(console.error);
    }
  };

  creatSandoqContact = async (newSandoq, cb) => {
    try {
      const response = await instance.post("/sandoq/sandoqcontact", newSandoq);
      this.sandoqs.push(response.data);
      cb();
    } catch (error) {
      console.error(console.error);
    }
  };

  deleteSandoq = async (sandoqId) => {
    try {
      await instance.delete(`/sandoq/${sandoqId}`);

      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");
      if (r == true) {
        this.sandoqs = this.sandoqs.filter((sandoq) => sandoq.id !== sandoqId);
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.log("GoalsStore -> deleteSandoq -> error", error);
    }
  };

  updateSandoq = async (updateSandoq, cb) => {
    try {
      const formData = new FormData();
      for (const key in updateSandoq) formData.append(key, updateSandoq[key]);
      await instance.put(`/sandoq/${updateSandoq.id}`, updateSandoq);

      const sandoq = this.sandoqs.find(
        (sandoq) => sandoq.id === updateSandoq.id
      );
      for (const key in sandoq) sandoq[key] = updateSandoq[key];
      cb();
    } catch (error) {
      console.log("sandoqstore -> updateSandoq -> error", error);
    }
  };
}

const sandoqStore = new SandoqStore();
sandoqStore.fetchSandoqs();
export default sandoqStore;
