import React from "react";
import authStore from "../stores/authStore";
import sandoqStore from "../stores/sandoqStore";
import { observer } from "mobx-react";

import { Busniesstabledata, TextStyletableTopBus, allgoals } from "../styles";
import { Link } from "react-router-dom";
import SandoqContacts from "./SandoqContacts";

function Sandooq() {
  const done = sandoqStore.sandoqs.filter((sandoq) => sandoq.done === true);

  const AllGoal = done.map((sandoq) => (
    <tr>
      <td align="center" direction="rtl">
        <allgoals>{sandoq.goal}</allgoals>
      </td>
    </tr>
  ));

  const Primary = sandoqStore.sandoqs.filter(
    (sandoq) => sandoq.primary === true
  );
  const PrimaryList = Primary.map((sandoq) => (
    <tr>
      <td>
        <Busniesstabledata>{sandoq.note}</Busniesstabledata>
      </td>
      <td>
        <Busniesstabledata>{sandoq.reason}</Busniesstabledata>
      </td>

      {sandoq.done !== true ? <td bgColor="red"> </td> : <td> </td>}

      {sandoq.done === true ? <td bgColor="green"> </td> : <td> </td>}

      <td>
        <Busniesstabledata>{sandoq.goal}</Busniesstabledata>
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatesandoq">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("sandoq", JSON.stringify(sandoq));
              }}
            >
              تعديل
            </button>
          </Link>
          <button
            class="btn btn-danger"
            onClick={() => sandoqStore.deleteSandoq(sandoq.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const Secondry = sandoqStore.sandoqs.filter(
    (sandoq) => sandoq.secondry === true
  );
  const SecondryList = Secondry.map((sandoq) => (
    <tr>
      <td>
        <Busniesstabledata>{sandoq.note}</Busniesstabledata>
      </td>
      <td>
        <Busniesstabledata>{sandoq.reason}</Busniesstabledata>
      </td>

      {sandoq.done !== true ? <td bgColor="red"> </td> : <td> </td>}

      {sandoq.done === true ? <td bgColor="green"> </td> : <td> </td>}

      <td>
        <Busniesstabledata>{sandoq.goal}</Busniesstabledata>
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatesandoq">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("sandoq", JSON.stringify(sandoq));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            class="btn btn-danger"
            onClick={() => sandoqStore.deleteSandoq(sandoq.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  return (
    <div>
      <div style={{ marginRight: "20px" }}>
        {authStore.user ? (
          <Link to="addsandoq">
            <div align="center">
              <button type="button" class="btn btn-primary">
                اضافة هدف
              </button>
            </div>
          </Link>
        ) : (
          ""
        )}
        <br></br>
        <h1 align="center"> صندوق شباب الشـطـي</h1>
        <br></br>
        <blockquote class="blockquote text-right">
          لتحقيق الهدف الأسمى لنشاطنا الاجتماعي، وهو تعزيز التواصل وتوثيق
          الروابط بين أفراد العائلة، وزيادة التعارف بينهم وخصوصاً فئة الشباب؛
          جاءت فكرة إنشاء «صندوق شباب الشطي»، فكل عمل اجتماعي لا بد من وجود دعم
          مالي، يضمن استمراره لتحقيق أهدافه، ولذلك كانت رسوم عضوية الصندوق
          الشهرية هي 10 دنانير{" "}
          <blockquote class="blockquote text-right">
            واستطاع الصندوق بفضل الله – عز وجل – أولا ثم دعم أبناء العائلة من
            تحقيق العديد من الأنشطة والإنجازات التي نفخر بها على مستوى العائلة
            ولله الحمد والمنة، وتجدون أغلب الأنشطة في القسم المخصص لها في هذا
            الموقع الالكتروني، وفي حساب الصندوق على الانستقرام @shababalshatti
            <blockquote class="blockquote text-right">
              وحرص الصندوق منذ أول تأسيسه لتعزيز الروابط الاجتماعية بين شباب
              :العائلة، وخلق أنشطة اجتماعي متميزة تجمعهم وتوثق تعارفهم، ومن ذلك
              <blockquote class="blockquote text-right">
                <blockquote class="blockquote text-right">
                  الأنشطة الرياضية المتنوعة -
                  <blockquote class="blockquote text-right">
                    الرحلات البحرية، الأنشطة -الصيفية-{" "}
                    <blockquote class="blockquote text-right">
                      الكشتات الشتوية والربيعية -
                      <blockquote class="blockquote text-right">
                        الملتقى الشهري للشباب يتنقل بين دواوين أبناء العائلة -
                        <blockquote class="blockquote text-right">
                          هدايا المتزوجين -
                          <blockquote class="blockquote text-right">
                            إنشاء هذا الموقع الإلكتروني للعائلة، ويشمل أخبار
                            العائلة، ورزنامة المناسبات، ودليل الدواوين، والدليل
                            التجاري-
                            <blockquote class="blockquote text-right">
                              @shababalshatti إنشاء وتفعيل حساب الصندوق في
                              الانستقرام-
                              <blockquote class="blockquote text-right">
                                تنظيم المسابقات الرمضانية، والحصول علي الرعاية
                                من مشاريع أبناء العائلة التجارية-
                                <blockquote class="blockquote text-right">
                                  توزيع الهدايا «النقصة» في المناسبات المختلفة
                                  مثل شهر رمضان المبارك وغيره-
                                  <blockquote class="blockquote text-right">
                                    تفعيل خدمةالرسائل القصيرة للمساهمين في
                                    الصندوق-
                                    <blockquote class="blockquote text-right">
                                      <h1>{""}</h1>
                                      :ومن المشاريع المستقبلية بإذن الله
                                      <h1>{""}</h1>
                                      <blockquote class="blockquote text-right">
                                        تنظيم مسابقات رمضانية لحفظ القرآن
                                        الكريم-
                                        <blockquote class="blockquote text-right">
                                          دورة رمضانية لكرة القدم لأبناء
                                          العائلة-
                                          <blockquote class="blockquote text-right">
                                            دورة للألعاب الشعبية «دومينة – داما
                                            – كيرم – كوت بوستة» لدواوين العائلة
                                            وروّادها-
                                            <blockquote class="blockquote text-right">
                                              تقديم هدية رمضان لأصحاب الدواوين
                                              الرسمية-
                                              <blockquote class="blockquote text-right">
                                                رحلات العمرة، والرحلات السياحية
                                                الترفيهية المتنوع-
                                              </blockquote>
                                            </blockquote>
                                          </blockquote>
                                        </blockquote>
                                      </blockquote>
                                    </blockquote>
                                  </blockquote>
                                </blockquote>
                              </blockquote>
                            </blockquote>
                          </blockquote>
                        </blockquote>
                      </blockquote>
                    </blockquote>
                  </blockquote>
                </blockquote>
              </blockquote>
            </blockquote>
          </blockquote>
        </blockquote>
      </div>

      {authStore.user ? (
        <h1 align="center" bgColor="light grey">
          {" "}
          أهداف صندوق الشباب{" "}
        </h1>
      ) : (
        ""
      )}

      <div>
        {authStore.user ? (
          <div class="table-responsive">
            <table class="table table-bordered ">
              <thead
                class="thead-dark"
                bg="light"
                expand="lg"
                sticky="top"
                position="fixed"
              >
                <td id="landury" colspan="5" align="center" bgColor="grey">
                  <h3> أهداف رئيسية</h3>
                </td>

                <tr>
                  <th>
                    <TextStyletableTopBus>ملاحظة</TextStyletableTopBus>
                  </th>

                  <th>
                    <TextStyletableTopBus>الاسباب</TextStyletableTopBus>
                  </th>
                  <th width="10%">
                    <TextStyletableTopBus>لم يتحقق</TextStyletableTopBus>
                  </th>
                  <th width="10%">
                    <TextStyletableTopBus>تحقق</TextStyletableTopBus>
                  </th>
                  <th>
                    <TextStyletableTopBus>الهدف</TextStyletableTopBus>
                  </th>

                  <th width="5%" class="middle">
                    تعديل/حذف
                  </th>
                </tr>
              </thead>
              <tbody class="table table-striped" bgColor="white">
                {PrimaryList}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
        <br></br>
        <div>
          {authStore.user ? (
            <div class="table-responsive">
              <table class="table table-bordered ">
                <thead
                  class="thead-dark"
                  bg="light"
                  expand="lg"
                  sticky="top"
                  position="fixed"
                >
                  <td id="landury" colspan="5" align="center" bgColor="grey">
                    <h3>أهداف ثانوية</h3>
                  </td>

                  <tr>
                    <th>
                      <TextStyletableTopBus>ملاحظة</TextStyletableTopBus>
                    </th>

                    <th>
                      <TextStyletableTopBus>الاسباب</TextStyletableTopBus>
                    </th>
                    <th width="10%">
                      <TextStyletableTopBus>لم يتحقق</TextStyletableTopBus>
                    </th>
                    <th width="10%">
                      <TextStyletableTopBus>تحقق</TextStyletableTopBus>
                    </th>
                    <th>
                      <TextStyletableTopBus>الهدف</TextStyletableTopBus>
                    </th>
                    {authStore.user ? (
                      <th width="5%" class="middle">
                        تعديل/حذف
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>

                <tbody class="table table-striped" bgColor="white">
                  {SecondryList}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div>
        {authStore.user ? (
          ""
        ) : (
          <div class="table-responsive">
            <table class="table table-bordered ">
              <thead
                class="thead-dark"
                bg="light"
                expand="lg"
                sticky="top"
                position="fixed"
              >
                <tr>
                  <th
                    align="center"
                    style={{ backgroundColor: "#2B547E", color: "white" }}
                  >
                    <h1
                      align="center"
                      style={{ backgroundColor: "#2B547E", color: "white" }}
                    >
                      {" "}
                      انجازات الصندوق{" "}
                    </h1>
                  </th>
                </tr>
              </thead>
              <tbody class="table table-striped" bgColor="white">
                {AllGoal}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <SandoqContacts />
    </div>
  );
}

export default observer(Sandooq);
