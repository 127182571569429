import React from "react";
import imageSliderStore from "../stores/imageSliderStore";
import informationStore from "../stores/informationStore";
import News from "./News";
import Player1 from "./Player1";
import ShortBrief from "./ShortBrief";
import SlidShow from "./SlidShow";
import { observer } from "mobx-react";
import authStore from "../stores/authStore";
import "../border.css";
import { Link } from "react-router-dom";
import { NemberOfvisitors } from "../styles";
import "../App.css";
import Alshatti3aza from "./Alshatti3aza";
// import Wedding from "./Wedding";
import CountUp from "react-countup";
import Redirect3Pic from "./Redirect3Pic";
import WeddingALShattii from "./WeddingALShattii";

function Home() {
  const TottalImages = imageSliderStore.imageSliders.filter(
    (imageSlider) => imageSlider.home === true
  );

  const Informtionbtn = informationStore.informations.map((information) => (
    <button
      type="button"
      className="btn btn-primary"
      onClick={(event) => {
        localStorage.setItem("information", JSON.stringify(information));
      }}
    >
      تعديل بيانات الصفحة
    </button>
  ));

  const VedioLink = informationStore.informations.map((information) => (
    <div>
      <br></br>
      <Player1 information={information} />
    </div>
  ));

  return (
    <div>
      <News />
      <div>
        <h1 className="rise-text">
          <div class="bodycss">الموقع الرسمي لعائلة الشطي- دولة الكويت</div>
        </h1>
      </div>
      <div classNameName="visitors">
        <section
          id="counter-stats"
          class="wow fadeInRight"
          data-wow-duration="1.4s"
        >
          <div class="col-lg-3 stats">
            عدد زوار الموقع
            <div class="counting">
              <i class="fa fa-user" aria-hidden="true"></i>
              <CountUp end={informationStore.informations[0].count} />
            </div>
          </div>
        </section>
      </div>
      {authStore.user ? (
        <Link to="/updatehomeinfo">
          <div align="center" padding="0.9em">
            {Informtionbtn}
          </div>
        </Link>
      ) : (
        ""
      )}
      {VedioLink}
      <SlidShow oneimage={TottalImages} />
      {authStore.user ? (
        <div align="center">
          <Link to={`/editsliderimage`}>
            <button type="button" className="btn btn-success">
              تعديل صور العرض
            </button>
          </Link>
        </div>
      ) : (
        ""
      )}
      <Redirect3Pic />

      <div>
        <Alshatti3aza />
        <WeddingALShattii />
      </div>

      <div className="gradient-border" id="box">
        <div className="calender">
          <div className="embed-responsive embed-responsive-4by3">
            <iframe
              title="calendar"
              className="embed-responsive-item"
              src="https://calendar.google.com/calendar/u/0/embed?src=shatitamim@gmail.com&ctz=Asia/Riyadh"
              align="center"
              scrolling="no"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Home);
