import React from "react";
import { observer } from "mobx-react";

import AboutUsPic from "../AboutUsPic.JPG";
import authStore from "../stores/authStore";

import { TextStyleHeader } from "../styles";
import informationStore from "../stores/informationStore";

function AboutUs() {
  const btn = informationStore.informations.map((information) =>
    authStore.user ? (
      <button
        type="button"
        class="btn btn-success"
        onClick={(event) => {
          localStorage.setItem("information", JSON.stringify(information));
          window.location = "/editaboutus";
        }}
      >
        تعديل محتوى الصفحة
      </button>
    ) : null
  );

  const T1 = informationStore.informations.map(
    (information) => information.Tittle
  );

  const T2 = informationStore.informations.map(
    (information) => information.Tittle2
  );

  const T3 = informationStore.informations.map(
    (information) => information.Tittle3
  );

  const T4 = informationStore.informations.map(
    (information) => information.Tittle4s
  );

  const T5 = informationStore.informations.map(
    (information) => information.aboutUsLong
  );

  const T6 = informationStore.informations.map(
    (information) => information.aboutUsLong2
  );

  const T7 = informationStore.informations.map(
    (information) => information.aboutUsLong3
  );

  const T8 = informationStore.informations.map(
    (information) => information.aboutUsLong4
  );

  return (
    <div>
      <div>
        <br></br>
        <h1>عائلة الشطي - شط بني تميم</h1>
        <br></br>
        <div align="center">{btn}</div>
        <div align="center">
          <img
            src={AboutUsPic}
            class="img-fluid"
            alt="Responsive pic"
            width="900px"
            height="200px"
          />
        </div>

        <div style={{ marginRight: "20px" }}>
          <blockquote class="blockquote text-right">
            <br></br>
            {T1}
            <br></br>
            <br></br>
            {T2}
            <br></br>
            {T3}
            <br></br>
            {T4}
            <br></br>
            <br></br> {T5}
            <br></br>
            {T6}
            <br></br>
            {T7} <br></br>
            <br></br>
            {T8} <br></br>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default observer(AboutUs);
