import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Carousel } from "react-responsive-carousel";

//styles
import {
  DeleteButtonStyled,
  DetailWrapper,
  UpdateButtonStyled,
} from "../styles";
//store
import authStore from "../stores/authStore";
import productStore from "../stores/productStore";
import { Link } from "react-router-dom";
import productImageStore from "../stores/productImageStore";
import WhatsupLink from "./WhatsupLink";
import AddproductImagBtn from "./buttons/AddproductImagBtn";
import { ArrowLeftOutlined } from "@material-ui/icons";

//component

const DetailProduct = () => {
  const productSlug = useParams().productSlug;

  const product = productStore.products.find(
    (_product) => _product.slug === productSlug
  );

  if (!product) return <Redirect to="/alshattishop" />;

  const ImagesFromProductStore = product.productimages.map((productImage) =>
    productImageStore.getProductImageId(productImage.id)
  );

  const ImageList = ImagesFromProductStore.map((pic) => (
    <div>
      <img src={pic.image} />

      <div>
        {authStore.user ? (
          <div>
            <br></br>
            <button
              class="btn btn-danger"
              onClick={() => productImageStore.deleteProductImage(pic, product)}
            >
              حذف{" "}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  ));

  return (
    <div>
      <Link to="/alshattishop">
        <br></br>{" "}
        <h5 align="left">
          <ArrowLeftOutlined /> {""}الرجوع للصفحة السابقة {""}
        </h5>
      </Link>
      <DetailWrapper>
        <br></br>
        <WhatsupLink />
        <div>
          <br></br>

          <div></div>
          {authStore.user ? (
            <div align="center">
              <br></br>
              <AddproductImagBtn product={product} />
            </div>
          ) : (
            ""
          )}
          <Carousel autoPlay infiniteLoop={true} showIndicators={false}>
            {ImageList}
          </Carousel>
        </div>
        <div>
          <p> {product.description}</p>
          <p>{product.price} KD </p>
        </div>
      </DetailWrapper>
    </div>
  );
};

export default observer(DetailProduct);
