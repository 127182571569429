import React from "react";
import imageSliderStore from "../stores/imageSliderStore";
import { observer } from "mobx-react";
import authStore from "../stores/authStore";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
function Mathon() {
  const FilterDoctorpic = imageSliderStore.imageSliders.filter(
    (image) => image.doctor === true
  );
  const ImageList = FilterDoctorpic.map((oneImage) => (
    <div align="center" padding="0.25rem" class="pt-5">
      <img
        // class="img-fluid img-thumbnail"
        position="relative"
        display="block"
        width="65%"
        height="650px"
        flex-direction="column"
        align="center"
        src={oneImage.image}
        // fluid
      />
      <h2>{""}</h2>
      {authStore.user ? (
        <div align="center">
          <button
            class="btn btn-danger"
            onClick={() => imageSliderStore.deleteImageSlider(oneImage.id)}
          >
            حذف{" "}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  ));

  return (
    <div>
      <div>
        <h3>{""}</h3>
        <h1>{""}</h1>
      </div>
      <div>
        <h1 align="center">الأطباء</h1>
      </div>
      {authStore.user ? (
        <div>
          <Link to="/Adddoctor">
            <div align="center" class="pt-5">
              <button type="button" class="btn btn-success">
                اضافة صورة
              </button>
            </div>
          </Link>
        </div>
      ) : (
        ""
      )}
      {ImageList}
    </div>
  );
}

export default observer(Mathon);
