import React from "react";
import informationStore from "../stores/informationStore";
import { observer } from "mobx-react";
function ShortBrief() {
  const ShortBriefLine = informationStore.informations.map(
    (information) => information.shortBrief
  );
}

export default observer(ShortBrief);
