import { observer } from "mobx-react";
import { useState } from "react";
import authStore from "../stores/authStore";
import productStore from "../stores/productStore";
import { Link } from "react-router-dom";

//style
import { DetailWrapperTest } from "../styles";
// store
//component
import OneItemProduct from "./OneItemProduct";
import SearchBar from "./SearchBar";
import WhatsupLink from "./WhatsupLink";

const AllProducts = () => {
  const [query, setQuery] = useState("");

  const filtterProducts = productStore.products.filter((product) =>
    product.name.toLowerCase().includes(query.toLowerCase())
  );

  const productList = filtterProducts.map((product) => (
    <OneItemProduct product={product} key={product.id} />
  ));

  return (
    <div>
      <br></br>
      <SearchBar setQuery={setQuery} />
      <div>
        <WhatsupLink />
      </div>
      {authStore.user ? (
        <div>
          <div align="center">
            <Link to="/addproduct">
              <button type="button" class="btn btn-success">
                اضافة منتج
              </button>
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
      <DetailWrapperTest>{productList}</DetailWrapperTest>
    </div>
  );
};

export default observer(AllProducts);
