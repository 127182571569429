import { makeAutoObservable } from "mobx";
import slugify from "react-slugify";

import instance from "./instance";

class FormStore {
  forms = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }
  getformId = (formId) => this.forms.find((form) => form.id === formId);

  fetchForms = async () => {
    try {
      const response = await instance.get("/forms");
      this.forms = response.data;
      this.loading = false;
    } catch (error) {
      console.error("form -> fetchForms -> error", error);
    }
  };

  creatForm = async (newForm) => {
    try {
      const response = await instance.post("/forms", newForm);
      this.forms.push(response.data);
    } catch (error) {
      console.error(console.error);
    }
  };
  updateContact = async (updateform, cb) => {
    try {
      const formData = new FormData();
      for (const key in updateform) formData.append(key, updateform[key]);
      await instance.put(`/forms/${updateform.id}`, updateform);

      const form = this.forms.find((form) => form.id === updateform.id);
      for (const key in form) form[key] = updateform[key];
      cb();
    } catch (error) {
      console.log("Contactstore -> updateform -> error", error);
    }
  };
}

const formStore = new FormStore();
formStore.fetchForms();
export default formStore;
