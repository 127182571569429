import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
//store
import authStore from "../stores/authStore";
import pictureStore from "../stores/pictureStore";
import { Tabledata } from "../styles";
import formStore from "../stores/formStore";
// import AddCoursesButton from "./buttons/AddCoursesButton";
function AllForm() {
  const Read = formStore.forms.filter((form) => form.Done === true);

  const ReadFormList = Read.map((form) => (
    <tr>
      <td align="center">
        <div class="table-responsive">
          <table class="table table-bordered ">
            <thead
              class="thead-light"
              bg="light"
              expand="lg"
              sticky="top"
              position="fixed"
            >
              <h4 align="center">
                <br></br>

                <Link to={`/forms/${form.slug}`}>{form.name}</Link>
              </h4>
            </thead>
          </table>
        </div>
      </td>
    </tr>
  ));

  const Unread = formStore.forms.filter((form) => form.Done === false);

  const unredForms = Unread.map((form) => (
    <tr>
      <td align="center">
        <div class="table-responsive">
          <table class="table table-bordered ">
            <thead
              class="thead-light"
              bg="light"
              expand="lg"
              sticky="top"
              position="fixed"
            >
              <h4 align="center">
                <br></br>

                <Link to={`/forms/${form.slug}`}>{form.name}</Link>
              </h4>
            </thead>
          </table>
        </div>
      </td>
    </tr>
  ));

  return (
    <div>
      <div
        style={{
          backgroundColor: "#2B547E",
          color: "white",
          align: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <br></br>
        <h1
          style={{
            backgroundColor: "#2B547E",
            color: "white",
            align: "center",
          }}
        >
          بيانات عائلة الشطي
        </h1>
        <br></br>
      </div>

      <div>
        <div class="table-responsive">
          <table class="table table-bordered ">
            <thead bg="light" expand="lg" sticky="top" position="fixed"></thead>
            {unredForms}
            <div
              style={{
                backgroundColor: "grey",
                color: "white",
                align: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <h1
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  align: "center",
                }}
              >
                تم الاطلاع
              </h1>
            </div>
            {ReadFormList}
          </table>
        </div>
      </div>
    </div>
  );
}

export default observer(AllForm);
