import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { observer } from "mobx-react";

import Modal from "react-bootstrap/Modal";

import sandoqStore from "../stores/sandoqStore";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    direction: "center",
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const SandoqContacts = () => {
  const classes = useStyles();
  const [contact, setContact] = useState({
    name: "",
    phonenumber: "",
    address: "",
    doneContact: false,
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [show2, setShow2] = useState(false);

  const handleChange = (event) => {
    setContact({ ...contact, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      contact.name.length !== 0 &&
      contact.address.length !== 0 &&
      contact.phonenumber.length !== 0
    ) {
      sandoqStore.creatSandoqContact(contact);
      setContact({
        name: "",
        phonenumber: "",
        address: "",
      });
      setShow2(true);
    } else if (
      contact.name.length === 0 ||
      contact.address.length === 0 ||
      contact.phonenumber.length === 0
    ) {
      alert("الرجاء االتاكد من تعبئة جميع الخانات");
    }
  };

  return (
    <div class="container">
      <div>
        <div>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                للتسجيل بالصندوق{" "}
              </Typography>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="name"
                      value={contact.name}
                      label="الاسم الرباعي"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      required
                      fullWidth
                      value={contact.phonenumber}
                      name="phonenumber"
                      label="رقم الهاتف"
                      flexDirection="rtl"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="outlined-multiline-flexible"
                      autoComplete="off"
                      name="address"
                      label="العنوان"
                      value={contact.address}
                      onChange={handleChange}
                      multiline
                    />
                  </Grid>
                </Grid>

                <>
                  <br></br>
                  <div align="center" align="center">
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#2B547E",
                        color: "white",
                        align: "center",
                      }}
                      onSubmit={handleSubmit}
                      fullWidth
                      variant="contained"
                      className={classes.submit}
                      onClick={handleSubmit}
                    >
                      تسجيل
                    </Button>
                  </div>
                  <br></br>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>تنبية</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>الرجاء التأكد من تعبئة جميع الخانات</Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                </>

                <>
                  <Modal
                    show={show2}
                    onHide={() => setShow2(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>لقد تم ارسال طلبيك سيتم التواصل معك في اقرب وقت</p>
                    </Modal.Body>
                  </Modal>
                </>

                <Grid container justify="flex-end">
                  <Grid item></Grid>
                </Grid>
              </form>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default observer(SandoqContacts);
