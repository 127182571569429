import { makeAutoObservable } from "mobx";
import instance from "./instance";

class BusinessStore {
  businesss = [];
  count = 0;
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getbusinessId = (businessId) =>
    this.businesss.find((business) => business.id === businessId);

  fetchBusinesss = async () => {
    try {
      const response = await instance.get("/business");
      this.businesss = response.data;
      this.loading = false;
    } catch (error) {
      console.error("Business -> fetchBusinesss -> error", error);
    }
  };

  creatBusiness = async (newBusiness, cb) => {
    try {
      const response = await instance.post("/business", newBusiness);
      this.businesss.push(response.data);
      cb();
    } catch (error) {
      console.error(console.error);
    }
  };

  deleteBusiness = async (businessId) => {
    try {
      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");
      if (r == true) {
        await instance.delete(`/business/${businessId}`);

        this.businesss = this.businesss.filter(
          (business) => business.id !== businessId
        );
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.log("GoalsStore -> deleteBusiness -> error", error);
    }
  };

  updateBusiness = async (updateBusiness, cb) => {
    try {
      const formData = new FormData();
      for (const key in updateBusiness)
        formData.append(key, updateBusiness[key]);
      await instance.put(`/business/${updateBusiness.id}`, updateBusiness);

      const business = this.businesss.find(
        (business) => business.id === updateBusiness.id
      );
      for (const key in business) business[key] = updateBusiness[key];
      cb();
    } catch (error) {
      console.log("businessstore -> updateBusiness -> error", error);
    }
  };
}

const businessStore = new BusinessStore();
businessStore.fetchBusinesss();
export default businessStore;
