import React from "react";
import dewanStore from "../stores/dewanStore";
import { observer } from "mobx-react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { BsGeoAlt } from "react-icons/bs";
import {
  TextStyle,
  TextStyleName,
  TextStyleAddress,
  TextStyletableTop,
  TextStyletday,
  TextStyleHeader,
} from "../styles";

import authStore from "../stores/authStore";

<link rel="stylesheet" media="(max-width: 800px)" href="example.css" />;

function AllDewan() {
  const Thread1 = (
    <thead style={{ backgroundColor: "#2B547E", color: "white" }}>
      {authStore.user ? (
        <th>
          {" "}
          <TextStyletableTop>تعديل/حذف</TextStyletableTop>
        </th>
      ) : (
        ""
      )}
      <th>
        <TextStyletableTop>ملاحظة</TextStyletableTop>{" "}
      </th>{" "}
      <th>
        <TextStyletableTop>العنوان</TextStyletableTop>{" "}
      </th>{" "}
      <th>
        <TextStyletableTop>صاحب الديوانية</TextStyletableTop>{" "}
      </th>
      <th width="20%">
        <TextStyletableTop></TextStyletableTop>{" "}
      </th>
    </thead>
  );

  const Sat = dewanStore.dewans.filter((dewan) => dewan.sat === true);
  const SatTable = Sat.map((dewan) => (
    <tbody>
      <tr>
        {authStore.user ? (
          <td width="5%" class="middle">
            <Link to="updatedewan">
              <button
                type="button"
                class="btn btn-success"
                onClick={(event) => {
                  localStorage.setItem("dewan", JSON.stringify(dewan));
                }}
              >
                تعديل
              </button>
            </Link>
            <br></br>
            <button
              class="btn btn-danger"
              onClick={() => dewanStore.deleteDewan(dewan.id)}
            >
              حذف{" "}
            </button>
          </td>
        ) : (
          ""
        )}
        <td>
          <TextStyleAddress>{dewan.note}</TextStyleAddress>
        </td>
        <td class="middle">
          <a href={dewan.googleMaps}>
            <TextStyleAddress>
              {dewan.address} <BsGeoAlt size="30" />
            </TextStyleAddress>
          </a>
        </td>
        <td width="40%">
          <TextStyleName>{dewan.name}</TextStyleName>

          <TextStyle>{dewan.fatherOf} </TextStyle>
          <TextStyle> {dewan.phoneNumber} :رقم الهاتف</TextStyle>
        </td>
        <td align="center">
          <img
            alt="img"
            display="block"
            marginLeft="auto"
            marginRight="auto"
            align="center"
            width="60px"
            height="150px"
            src={dewan.image}
          />
        </td>
      </tr>
    </tbody>
  ));

  const Sun = dewanStore.dewans.filter((dewan) => dewan.sun === true);
  const SunTable = Sun.map((dewan) => (
    <tbody>
      <tr>
        {authStore.user ? (
          <td width="5%" class="middle">
            <Link to="updatedewan">
              <button
                type="button"
                class="btn btn-success"
                onClick={(event) => {
                  localStorage.setItem("dewan", JSON.stringify(dewan));
                }}
              >
                تعديل
              </button>
              <br></br>
            </Link>
            <br></br>
            <button
              class="btn btn-danger"
              onClick={() => dewanStore.deleteDewan(dewan.id)}
            >
              حذف{" "}
            </button>
          </td>
        ) : (
          ""
        )}
        <td>
          <TextStyleAddress>{dewan.note}</TextStyleAddress>
        </td>
        <td class="middle">
          <a href={dewan.googleMaps}>
            {" "}
            <TextStyleAddress>
              {dewan.address} <BsGeoAlt size="30" />
            </TextStyleAddress>
          </a>
        </td>
        <td>
          <TextStyleName>{dewan.name}</TextStyleName>

          <TextStyle>{dewan.fatherOf} </TextStyle>
          <TextStyle> {dewan.phoneNumber} :رقم الهاتف</TextStyle>
        </td>
        <td align="center">
          <img
            alt="img"
            display="block"
            marginLeft="auto"
            marginRight="auto"
            align="center"
            width="60px"
            height="150px"
            src={dewan.image}
          />
        </td>
      </tr>
    </tbody>
  ));

  const Mon = dewanStore.dewans.filter((dewan) => dewan.mon === true);
  const MonTable = Mon.map((dewan) => (
    <tbody>
      <tr>
        {authStore.user ? (
          <td width="5%" class="middle">
            <Link to="updatedewan">
              <button
                type="button"
                class="btn btn-success"
                onClick={(event) => {
                  localStorage.setItem("dewan", JSON.stringify(dewan));
                }}
              >
                تعديل
              </button>
              <br></br>
            </Link>
            <br></br>
            <button
              class="btn btn-danger"
              onClick={() => dewanStore.deleteDewan(dewan.id)}
            >
              حذف{" "}
            </button>
          </td>
        ) : (
          ""
        )}
        <td>
          <TextStyleAddress>{dewan.note}</TextStyleAddress>
        </td>
        <td class="middle">
          <a href={dewan.googleMaps}>
            {" "}
            <TextStyleAddress>
              {dewan.address} <BsGeoAlt size="30" />
            </TextStyleAddress>
          </a>
        </td>
        <td>
          <TextStyleName>{dewan.name}</TextStyleName>

          <TextStyle>{dewan.fatherOf} </TextStyle>
          <TextStyle> {dewan.phoneNumber} :رقم الهاتف</TextStyle>
        </td>
        <td align="center">
          <img
            alt="img"
            display="block"
            marginLeft="auto"
            marginRight="auto"
            align="center"
            width="60px"
            height="150px"
            src={dewan.image}
          />
        </td>
      </tr>
    </tbody>
  ));
  const Tue = dewanStore.dewans.filter((dewan) => dewan.tue === true);
  const TueTable = Tue.map((dewan) => (
    <tbody>
      <tr>
        {authStore.user ? (
          <td width="5%" class="middle">
            <Link to="updatedewan">
              <button
                type="button"
                class="btn btn-success"
                onClick={(event) => {
                  localStorage.setItem("dewan", JSON.stringify(dewan));
                }}
              >
                تعديل
              </button>
            </Link>
            <br></br>
            <button
              class="btn btn-danger"
              onClick={() => dewanStore.deleteDewan(dewan.id)}
            >
              حذف{" "}
            </button>
          </td>
        ) : (
          ""
        )}
        <td>
          <TextStyleAddress>{dewan.note}</TextStyleAddress>
        </td>
        <td class="middle">
          <a href={dewan.googleMaps}>
            {" "}
            <TextStyleAddress>
              {dewan.address} <BsGeoAlt size="30" />
            </TextStyleAddress>
          </a>
        </td>
        <td>
          <TextStyleName>{dewan.name}</TextStyleName>

          <TextStyle>{dewan.fatherOf} </TextStyle>
          <TextStyle> {dewan.phoneNumber} :رقم الهاتف</TextStyle>
        </td>
        <td align="center">
          <img
            alt="img"
            display="block"
            marginLeft="auto"
            marginRight="auto"
            align="center"
            width="60px"
            height="150px"
            src={dewan.image}
          />
        </td>
      </tr>
    </tbody>
  ));

  const Wen = dewanStore.dewans.filter((dewan) => dewan.wen === true);
  const WenTable = Wen.map((dewan) => (
    <tbody>
      <tr>
        {authStore.user ? (
          <td width="5%" class="middle">
            <Link to="updatedewan">
              <button
                type="button"
                class="btn btn-success"
                onClick={(event) => {
                  localStorage.setItem("dewan", JSON.stringify(dewan));
                }}
              >
                تعديل
              </button>
            </Link>
            <br></br>
            <button
              class="btn btn-danger"
              onClick={() => dewanStore.deleteDewan(dewan.id)}
            >
              حذف{" "}
            </button>
          </td>
        ) : (
          ""
        )}
        <td>
          <TextStyleAddress>{dewan.note}</TextStyleAddress>
        </td>
        <td class="middle">
          <a href={dewan.googleMaps}>
            {" "}
            <TextStyleAddress>
              {dewan.address} <BsGeoAlt size="30" />
            </TextStyleAddress>
          </a>
        </td>
        <td>
          <TextStyleName>{dewan.name}</TextStyleName>

          <TextStyle>{dewan.fatherOf} </TextStyle>
          <TextStyle> {dewan.phoneNumber} :رقم الهاتف</TextStyle>
        </td>
        <td align="center">
          <img
            alt="img"
            display="block"
            marginLeft="auto"
            marginRight="auto"
            align="center"
            width="60px"
            height="150px"
            src={dewan.image}
          />
        </td>
      </tr>
    </tbody>
  ));

  const Thu = dewanStore.dewans.filter((dewan) => dewan.thu === true);
  const ThuTable = Thu.map((dewan) => (
    <tbody>
      <tr>
        {authStore.user ? (
          <td width="5%" class="middle">
            <Link to="updatedewan">
              <button
                type="button"
                class="btn btn-success"
                onClick={(event) => {
                  localStorage.setItem("dewan", JSON.stringify(dewan));
                }}
              >
                تعديل
              </button>
            </Link>
            <br></br>
            <button
              class="btn btn-danger"
              onClick={() => dewanStore.deleteDewan(dewan.id)}
            >
              حذف{" "}
            </button>
          </td>
        ) : (
          ""
        )}
        <td>
          <TextStyleAddress>{dewan.note}</TextStyleAddress>
        </td>
        <td class="middle">
          <a href={dewan.googleMaps}>
            {" "}
            <TextStyleAddress>
              {dewan.address} <BsGeoAlt size="30" />
            </TextStyleAddress>
          </a>
        </td>
        <td width="40%">
          <TextStyleName>{dewan.name}</TextStyleName>

          <TextStyle>{dewan.fatherOf} </TextStyle>
          <TextStyle> {dewan.phoneNumber} :رقم الهاتف</TextStyle>
        </td>
        <td width="10%" align="center">
          <img
            alt="img"
            display="block"
            marginLeft="auto"
            marginRight="auto"
            align="center"
            width="60px"
            height="150px"
            src={dewan.image}
          />
        </td>
      </tr>
    </tbody>
  ));
  const Fri = dewanStore.dewans.filter((dewan) => dewan.fri === true);
  const FriTable = Fri.map((dewan) => (
    <tbody>
      <tr>
        {authStore.user ? (
          <td width="5%" class="middle">
            <Link to="updatedewan">
              <button
                type="button"
                class="btn btn-success"
                onClick={(event) => {
                  localStorage.setItem("dewan", JSON.stringify(dewan));
                }}
              >
                تعديل
              </button>
            </Link>

            <button
              class="btn btn-danger"
              onClick={() => dewanStore.deleteDewan(dewan.id)}
            >
              حذف{" "}
            </button>
          </td>
        ) : (
          ""
        )}
        <td>
          <TextStyleAddress>{dewan.note}</TextStyleAddress>
        </td>
        <td class="middle">
          <a href={dewan.googleMaps}>
            {" "}
            <TextStyleAddress>
              {dewan.address} <BsGeoAlt size="30" />
            </TextStyleAddress>
          </a>
        </td>
        <td width="40%">
          <TextStyleName>{dewan.name}</TextStyleName>

          <TextStyle>{dewan.fatherOf} </TextStyle>
          <TextStyle> {dewan.phoneNumber} :رقم الهاتف</TextStyle>
        </td>
        <td width="10%" align="center">
          <img
            alt="img"
            display="block"
            marginLeft="auto"
            marginRight="auto"
            align="center"
            width="60px"
            height="150px"
            src={dewan.image}
          />
        </td>
      </tr>
    </tbody>
  ));

  return (
    <div style={{ backgroundColor: "white" }}>
      <div class="table-responsive">
        <div align="center">
          {authStore.user ? (
            <Link to="AddDewan">
              <button type="button" class="btn btn-primary">
                اضافة ديوانية
              </button>
            </Link>
          ) : (
            ""
          )}
        </div>
        <br></br>
        <h1>دليل دواويين عائلة الشطي</h1>
        <div class="table-responsive">
          <TextStyletday> دواويين يوم السبت</TextStyletday>
          <div class="table-responsive">
            <Table striped bordered hover bgColor="white">
              {Thread1}
              {SatTable}
            </Table>
          </div>
          <TextStyletday> دواويين يوم الأحد</TextStyletday>
          <Table striped bordered hover bgColor="white">
            {Thread1}
            {SunTable}
          </Table>

          <TextStyletday> دواويين يوم الأثنين</TextStyletday>
          <Table striped bordered hover bgColor="white">
            {Thread1}
            {MonTable}
          </Table>
          <TextStyletday> دواويين يوم الثلاثاء</TextStyletday>

          <Table striped bordered hover bgColor="white">
            {Thread1}
            {TueTable}
          </Table>
          <TextStyletday> دواويين يوم الأربعاء</TextStyletday>

          <Table striped bordered hover bgColor="white">
            {Thread1}
            {WenTable}
          </Table>
          <TextStyletday> دواويين يوم الخميس</TextStyletday>

          <Table striped bordered hover bgColor="white">
            {Thread1}
            {ThuTable}
          </Table>
          <TextStyletday> دواويين يوم الجمعة</TextStyletday>

          <Table striped bordered hover bgColor="white">
            {Thread1}
            {FriTable}
          </Table>
        </div>
      </div>
    </div>
  );
}

export default observer(AllDewan);
