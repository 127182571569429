import productStore from "../stores/productStore";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { observer } from "mobx-react";
import productImageStore from "../stores/productImageStore";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const AddProduct = () => {
  const classes = useStyles();

  const [product, setproduct] = useState({
    image: "",
    name: "",
    description: "",
    price: "",
    slug: "productdetails",
  });

  const [pic, setPic] = useState({
    image: "",
  });

  const handleChange = (event) => {
    console.log(product);
    setproduct({ ...product, [event.target.name]: event.target.value });
  };

  const handleImg = (event) => {
    setproduct({ ...product, image: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(product);
    console.log(handleSubmit);
    productStore.creatProduct(product, () => {
      window.location = "/alshattishop";
    });
  };

  return (
    <Container flexDirection="rtl" component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          اضافه منتج
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={product.name}
            name="name"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="اسم المنتج"
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            label="وصف المنتج"
            value={product.description}
            name="description"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            label="سعر المنتج "
            value={product.price}
            name="price"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />

          <label>صوره المنتج</label>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            name="image"
            onChange={handleImg}
            type="file"
            flexDirection="rtl"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            اضافة
          </Button>

          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default observer(AddProduct);
