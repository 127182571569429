import React from "react";
import { Carousel } from "react-responsive-carousel";
import { observer } from "mobx-react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function SlidShow({ oneimage }) {
  const ImageList = oneimage.map((Oneemage) => (
    <div>
      <img src={Oneemage.image} alt="img" />
      <h3>{Oneemage.caption}</h3>
    </div>
  ));

  return (
    <div style={{ marginTop: "30px" }}>
      <Carousel
        autoPlay
        infiniteLoop={true}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
      >
        {ImageList}
      </Carousel>
    </div>
  );
}
export default observer(SlidShow);
