import React from "react";
import imageSliderStore from "../stores/imageSliderStore";
import { observer } from "mobx-react";
import ItemSliderPic from "./ItemSliderPic";
import { DetailWrapperTest } from "../styles";
import authStore from "../stores/authStore";
import { Link } from "react-router-dom";

function AllSLiderHomePic() {
  const Filterhomepic = imageSliderStore.imageSliders.filter(
    (image) => image.home === true
  );
  const ImageList = Filterhomepic.map((oneImage) => (
    <ItemSliderPic oneImage={oneImage} />
  ));
  ///

  const FilterFurnalpic = imageSliderStore.imageSliders.filter(
    (image) => image.funeral === true
  );
  const ImageFurnalList = FilterFurnalpic.map((oneImage) => (
    <ItemSliderPic oneImage={oneImage} />
  ));
  //
  const FilterWeddingpic = imageSliderStore.imageSliders.filter(
    (image) => image.occationWed === true
  );
  const ImageWeddingList = FilterWeddingpic.map((oneImage) => (
    <ItemSliderPic oneImage={oneImage} />
  ));

  return (
    <div>
      <div>
        {authStore.user ? (
          <div>
            <div align="center">
              <Link to="/addWeddingimage">
                <br></br>
                <button type="button" class="btn btn-success">
                  اضافة صور للاعراس
                </button>
              </Link>
            </div>
            <br></br>
            <h1 align="center"> صور الاعراس</h1>
            <DetailWrapperTest>{ImageWeddingList}</DetailWrapperTest>{" "}
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        {authStore.user ? (
          <div>
            <div align="center">
              <Link to="/addfurnalimage">
                <br></br>
                <button type="button" class="btn btn-success">
                  اضافة صور عزاء
                </button>
              </Link>
            </div>
            <br></br>
            <h1 align="center"> صور العزاء</h1>
            <DetailWrapperTest>{ImageFurnalList}</DetailWrapperTest>{" "}
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        <br></br>
        {authStore.user ? (
          <div>
            <div align="center">
              <Link to="/addsliderimage">
                <br></br>
                <button type="button" class="btn btn-success">
                  اضافة صورة للالبوم المتحرك
                </button>
              </Link>
            </div>
            <br></br>
            <h1 align="center"> الالبوم المتحرك</h1>
            <DetailWrapperTest>{ImageList}</DetailWrapperTest>{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default observer(AllSLiderHomePic);
