import React from "react";
import { observer } from "mobx-react";

import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import authStore from "../stores/authStore";
import Navbar from "react-bootstrap/Navbar";
import { Busniesstabledata, TextStyletableTopBus } from "../styles";

import businessStore from "../stores/businessStore";

function AllBusiness() {
  const Food = businessStore.businesss.filter((bus) => bus.food === true);

  const FoodList = Food.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const Sweet = businessStore.businesss.filter((bus) => bus.dessert === true);

  const SweetList = Sweet.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const Drinks = businessStore.businesss.filter(
    (bus) => bus.coffeAndDrinks === true
  );

  const DrinksList = Drinks.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const landury = businessStore.businesss.filter((bus) => bus.landury === true);

  const LanduryList = landury.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const Cars = businessStore.businesss.filter((bus) => bus.cars === true);

  const CarsList = Cars.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const helth = businessStore.businesss.filter((bus) => bus.health === true);

  const HelthList = helth.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const HandMade = businessStore.businesss.filter(
    (bus) => bus.handmade === true
  );

  const HandMadeList = HandMade.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>

      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));
  const perfume = businessStore.businesss.filter((bus) => bus.perfume === true);

  const PerfumeList = perfume.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const manclothes = businessStore.businesss.filter(
    (bus) => bus.manclothes === true
  );

  const ManList = manclothes.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="7px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              align="center"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>
          <br></br>
          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const womenclothes = businessStore.businesss.filter(
    (bus) => bus.womenclothes === true
  );

  const WomenList = womenclothes.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const salons = businessStore.businesss.filter((bus) => bus.salons === true);

  const SalonsList = salons.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  const photo = businessStore.businesss.filter((bus) => bus.photos === true);

  const photoList = photo.map((business) => (
    <tr>
      <td width="20px">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>
      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));
  const others = businessStore.businesss.filter((bus) => bus.others === true);

  const OthersList = others.map((business) => (
    <tr>
      <td width="20px" backgroundColor="white	">
        <Busniesstabledata>{business.address}</Busniesstabledata>
      </td>

      <td width="20px">
        <Busniesstabledata>{business.owner}</Busniesstabledata>
      </td>
      <td width="10px">
        <Busniesstabledata>{business.phoneNumber}</Busniesstabledata>
      </td>
      <td width="30px">
        <a href={business.instagramLink}>
          {" "}
          <Busniesstabledata>{business.instagramAcount} </Busniesstabledata>
        </a>{" "}
      </td>

      {authStore.user ? (
        <td width="5%" class="middle">
          <Link to="updatebusiness">
            <button
              type="button"
              class="btn btn-success"
              onClick={(event) => {
                localStorage.setItem("business", JSON.stringify(business));
              }}
            >
              تعديل
            </button>
          </Link>

          <button
            type="button"
            class="btn btn-outline-danger"
            align="center"
            onClick={() => businessStore.deleteBusiness(business.id)}
          >
            حذف{" "}
          </button>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));
  return (
    <div style={{ backgroundColor: "white" }}>
      <br></br>
      <h1 align="center">المشاريع التجارية</h1>
      {authStore.user ? (
        <Link to="addbusiness">
          <div align="center">
            <button type="button" class="btn btn-primary">
              اضافة مشروع
            </button>
          </div>
        </Link>
      ) : (
        ""
      )}
      <h1>{""}</h1>
      <Navbar
        align="center"
        sticky="top"
        position="fixed"
        verticalAlign="top"
        textAlign="center"
        color="black"
        bgColor="black"
      >
        نوع المشروع
        <div backgroundColor="black">
          <NavDropdown id="basic-nav-dropdown center" align="center">
            <NavDropdown.Item alignItems="center">
              مأكولات/مطاعم
            </NavDropdown.Item>

            <NavDropdown.Item href="#sweet" alignItems="center">
              حلويات
            </NavDropdown.Item>

            <NavDropdown.Item href="#drinks" alignItems="center">
              قهوة/مشروبات
            </NavDropdown.Item>

            <NavDropdown.Item href="#landury" alignItems="center">
              مصبغة ملابس
            </NavDropdown.Item>

            <NavDropdown.Item href="#cars" alignItems="center">
              غسيل سيارات/كراجات
            </NavDropdown.Item>

            <NavDropdown.Item href="#health" alignItems="center">
              صحة/رياضة
            </NavDropdown.Item>

            <NavDropdown.Item href="#handmade" alignItems="center">
              اعمال يدوية/حرفية
            </NavDropdown.Item>

            <NavDropdown.Item href="#perfume" alignItems="center">
              عطور/بخور
            </NavDropdown.Item>

            <NavDropdown.Item href="#man" alignItems="center">
              ملابس رجالية/مسابيح
            </NavDropdown.Item>

            <NavDropdown.Item href="#women" alignItems="center">
              ملابس نسائية/اكسسوارات
            </NavDropdown.Item>

            <NavDropdown.Item href="#salons" alignItems="center">
              صالونات نسائية
            </NavDropdown.Item>

            <NavDropdown.Item href="#photos" alignItems="center">
              مصورين/مصممين/شركات انتاج
            </NavDropdown.Item>

            <NavDropdown.Divider />
            <NavDropdown.Item href="#others" alignItems="center">
              اخرى
            </NavDropdown.Item>
          </NavDropdown>
        </div>
        <i class="fas fa-filter"></i>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Navbar>
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-offset="0"
        tabindex="0"
      >
        <div class="table-responsive">
          <table class="table table-bordered ">
            <thead
              style={{ backgroundColor: "#2B547E", color: "white" }}
              bg="light"
              expand="lg"
              sticky="top"
              position="fixed"
            >
              <tr id="food">
                <th>
                  <TextStyletableTopBus>العنوان</TextStyletableTopBus>
                </th>

                <th>
                  <TextStyletableTopBus>نوع المشروع</TextStyletableTopBus>
                </th>
                <th>
                  <TextStyletableTopBus>رقم الهاتف</TextStyletableTopBus>
                </th>
                <th width="10%" id="sweet">
                  <TextStyletableTopBus>حساب الانستقرام</TextStyletableTopBus>
                </th>
                {authStore.user ? (
                  <th width="5%" class="middle">
                    تعديل/حذف
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <td
              id="sweet"
              colspan="4"
              align="center"
              style={{ backgroundColor: "lightgrey" }}
            >
              مأكولات/مطاعم
            </td>
            <tbody id="food" bgColor="white" class="table table-striped">
              {FoodList}
            </tbody>
            <td
              id="drinks"
              colspan="4"
              align="center"
              style={{ backgroundColor: "	#519e8a	" }}
            >
              حلويات
            </td>

            <tbody class="table table-striped" bgColor="white">
              {SweetList}
            </tbody>

            <td
              id="landury"
              colspan="4"
              align="center"
              style={{ backgroundColor: "lightgrey" }}
            >
              قهوة/مشروبات{" "}
            </td>

            <tbody class="table table-striped" bgColor="white">
              {DrinksList}
            </tbody>

            <td
              id="cars"
              colspan="4"
              align="center"
              style={{ backgroundColor: "	#519e8a	" }}
            >
              مصبغة ملابس{" "}
            </td>

            <tbody class="table table-striped" bgColor="white">
              {LanduryList}
            </tbody>
            <td
              id="health"
              colspan="4"
              align="center"
              style={{ backgroundColor: "lightgrey" }}
            >
              غسيل سيارات/كراجات
            </td>
            <tbody class="table table-striped" bgColor="white">
              {CarsList}
            </tbody>

            <td
              id="handmade"
              colspan="4"
              align="center"
              style={{ backgroundColor: "	#519e8a	" }}
            >
              صحة ورياضة{" "}
            </td>

            <tbody class="table table-striped" bgColor="white">
              {HelthList}
            </tbody>
            <td
              id="perfume"
              colspan="4"
              align="center"
              style={{ backgroundColor: "lightgrey" }}
            >
              اعمال يدوية/حرفية{" "}
            </td>

            <tbody class="table table-striped" bgColor="white">
              {HandMadeList}
            </tbody>

            <td
              id="man"
              colspan="4"
              align="center"
              style={{ backgroundColor: "	white	" }}
            >
              عطور/بخور{" "}
            </td>

            <tbody class="table table-striped" bgColor="white">
              {PerfumeList}
            </tbody>

            <td
              id="women"
              colspan="4"
              align="center"
              style={{ backgroundColor: "lightgrey" }}
            >
              ملابس رجالية/مسابيح{" "}
            </td>

            <tbody class="table table-striped" bgColor="white">
              {ManList}
            </tbody>

            <td
              id="salons"
              colspan="4"
              align="center"
              style={{ backgroundColor: "	#519e8a	" }}
            >
              ملابس نسائية/اكسسوارات{" "}
            </td>

            <tbody class="table table-striped" bgColor="white">
              {WomenList}
            </tbody>

            <td
              colspan="4"
              id="photos"
              align="center"
              style={{ backgroundColor: "lightgrey" }}
            >
              صالونات نسائية{" "}
            </td>

            <tbody class="table table-striped" bgColor="white">
              {SalonsList}
            </tbody>

            <td
              id="others"
              colspan="4"
              align="center"
              style={{ backgroundColor: "	#519e8a	" }}
            >
              مصورين/مصممين/شركات انتاج
            </td>

            <tbody class="table table-striped" bgColor="white">
              {photoList}
            </tbody>

            <td
              colspan="4"
              align="center"
              style={{ backgroundColor: "lightgrey" }}
            >
              اخرى
            </td>

            <tbody class="table table-striped" bgColor="#519e8a" id="othes">
              {OthersList}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default observer(AllBusiness);
