import Modal from "react-modal";
import { useState } from "react";

//styles
import { CreateButtonStyled } from "../../styles";

import imageSliderStore from "../../stores/imageSliderStore";

const UpdateVedioItemModel = ({ isOpen, closeModal, oldimage }) => {
  const [pic, picPic] = useState(oldimage);

  const handleChange = (event) => {
    picPic({ ...pic, [event.target.name]: event.target.value });
  };
  const handleImg = (event) => {
    picPic({ ...pic, image: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    closeModal();
    imageSliderStore.updateImageSlider(pic);
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel="pic Modal">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h3 align="right"> تعديل </h3>
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <h3 align="right">العنوان</h3>
          <input
            value={pic.vedioTittle}
            name="vedioTittle"
            onChange={handleChange}
            type="text"
            className="form-control"
          />
        </div>

        <div className="col-12">
          <h3 align="right">رابط الفيديو</h3>
          <input
            value={pic.vedioLink}
            name="vedioLink"
            onChange={handleChange}
            type="text"
            className="form-control"
          />
        </div>

        <p></p>

        <CreateButtonStyled>تعديل</CreateButtonStyled>
      </form>
    </Modal>
  );
};

export default UpdateVedioItemModel;
