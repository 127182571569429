import { useState } from "react";
import Button from "@material-ui/core/Button";

//styles

//component
import ProductModel from "../models/ProductModel";

const UpdateButton = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return (
    <>
      <Button variant="contained" color="secondary" onClick={openModal}>
        تعديل
      </Button>
      <ProductModel
        oldProduct={product}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  );
};
export default UpdateButton;
