import React from "react";
import imageSliderStore from "../stores/imageSliderStore";

import SlidShow from "./SlidShow";
import { observer } from "mobx-react";
import authStore from "../stores/authStore";
import { Link } from "react-router-dom";
import Faz3aVedioItem from "./Faz3aVedioItem";
import { ArrowLeftOutlined } from "@material-ui/icons";
function OldFaz3a() {
  const TottalImages2 = imageSliderStore.imageSliders.filter(
    (imageSlider) => imageSlider.faz3a === true
  );

  const Video = imageSliderStore.imageSliders.filter(
    (imageSlider) => imageSlider.VidieoOldFaz3a === true
  );

  const VedioList = Video.map((imageSlider) => (
    <Faz3aVedioItem imageSlider={imageSlider} />
  ));
  return (
    <div>
      <br></br>
      <Link to="/fazaa">
        <div>
          <br></br>{" "}
          <h5>
            <ArrowLeftOutlined /> {""}الرجوع للصفحة السابقة {""}
          </h5>
        </div>
      </Link>
      <h1 align="center">المشاريع السابقة</h1>
      <br></br>

      <div bgcolor="white">
        {authStore.user ? (
          <div adlign="center">
            <Link to={`/editsliderimg`}>
              <div align="center">
                <button type="button" class="btn btn-success">
                  تعديل صور العرض{" "}
                </button>
              </div>
            </Link>
          </div>
        ) : (
          ""
        )}
        <SlidShow oneimage={TottalImages2} />

        {authStore.user ? (
          <div adlign="center">
            <Link to={`/addvideo`}>
              <div align="center">
                <button type="button" class="btn btn-success">
                  اضافة فيديو{" "}
                </button>
              </div>
            </Link>
          </div>
        ) : (
          ""
        )}
        {VedioList}
      </div>
    </div>
  );
}

export default observer(OldFaz3a);
