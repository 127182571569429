import { makeAutoObservable } from "mobx";
import slugify from "react-slugify";

import instance from "./instance";

class AlbumStore {
  albums = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  fetchALbums = async () => {
    try {
      const response = await instance.get("/catalouge");
      this.albums = response.data;
      this.loading = false;
    } catch (error) {
      console.error("AlbumStore -> fetchAlbums -> error", error);
    }
  };

  deleteAlbum = async (album) => {
    try {
      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");
      if (r == true) {
        await instance.delete(`/catalouge/${album.id}`);
        this.albums = this.albums.filter((_album) => _album.id !== album.id);
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.log("GoalsStore -> deleteBusiness -> error", error);
    }
  };

  creatAlbum = async (newAlbum, cb) => {
    try {
      const response = await instance.post("/catalouge", newAlbum);
      this.albums.push(response.data);
      cb();
    } catch (error) {
      console.error(console.error);
    }
  };
}
const albumStore = new AlbumStore();
albumStore.fetchALbums();
export default albumStore;
