import React from "react";
import informationStore from "../stores/informationStore";
import { observer } from "mobx-react";
function News() {
  const NewLine = informationStore.informations.map(
    (information) => information.news
  );

  return (
    <div
      align="center"
      style={{
        backgroundColor: "#2B547E",
        color: "white",
        Line: "underline",
      }}
    >
      <marquee direction="right">{NewLine}</marquee>
    </div>
  );
}

export default observer(News);
