import React from "react";
import imageSliderStore from "../stores/imageSliderStore";
import { observer } from "mobx-react";
import ItemSliderPic from "./ItemSliderPic";
import { DetailWrapperTest } from "../styles";
import authStore from "../stores/authStore";
import { Link } from "react-router-dom";

function AllSLiderFaz3aPic() {
  const FilterFaz3apic = imageSliderStore.imageSliders.filter(
    (image) => image.faz3a === true
  );
  const ImageList = FilterFaz3apic.map((oneImage) => (
    <ItemSliderPic oneImage={oneImage} />
  ));

  return (
    <div>
      <h1>{""}</h1>
      {authStore.user ? (
        <div align="center">
          <Link to="/Addsliderimg">
            <button type="button" class="btn btn-success">
              اضافة صورة
            </button>
          </Link>
          <DetailWrapperTest>{ImageList}</DetailWrapperTest>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default observer(AllSLiderFaz3aPic);
