import Modal from "react-modal";
import { useState } from "react";
import "../../App.css";

//styles
import { CreateButtonStyled } from "../../styles";

//store
import faz3aStore from "../../stores/faz3aStore";

const Faz3aModel = ({ isOpen, closeModal, ـfaz3a }) => {
  const [faz3a, setFaz3a] = useState(ـfaz3a);
  const handleChange = (event) => {
    setFaz3a({ ...faz3a, [event.target.name]: event.target.value });
  };
  const handleImg = (event) => {
    setFaz3a({ ...faz3a, image: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    closeModal();

    faz3aStore.updateFaz3a(faz3a, () => {
      window.location = "/fazaas";
    });
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="faz3a Modal"
    >
      <br></br>
      <br></br>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h3 align="right">تعديل</h3>
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <h3 align="right">الجمعية</h3>
          <input
            value={faz3a.jam3ya}
            name="jam3ya"
            type="text"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="col-12">
          <h3 align="right">العنوان</h3>
          <input
            value={faz3a.tittle}
            name="tittle"
            type="text"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="col-12">
          {" "}
          <h3 align="right">الرابط</h3>
          <input
            value={faz3a.link}
            name="link"
            onChange={handleChange}
            type="text"
            className="form-control"
          />
        </div>
        <div className="col-12">
          {" "}
          <h3 align="right">الوصف</h3>
          <input
            value={faz3a.description}
            name="description"
            onChange={handleChange}
            type="text"
            className="form-control"
          />
        </div>
        <div className="col-12">
          {" "}
          <h3 align="right">صوره</h3>
          <input
            name="image"
            onChange={handleImg}
            type="file"
            className="form-control"
          />
        </div>

        <br></br>
        <div align="right">
          <br></br>
          <button class="btn btn-primary" align="right">
            تعديل
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default Faz3aModel;
