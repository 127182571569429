import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { observer } from "mobx-react";

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import imageSliderStore from "../stores/imageSliderStore";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-flip/effect-flip.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "../border.css";

// import Swiper core and required modules
import SwiperCore, {
  EffectFlip,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/core";
import { TextStyleAddress } from "../styles";
import { BsGeoAlt } from "react-icons/bs";

// install Swiper modules
SwiperCore.use([EffectFlip, Pagination, Navigation, Autoplay]);

function Funiral() {
  const TottalImages = imageSliderStore.imageSliders.filter(
    (imageSlider) => imageSlider.funeral === true
  );

  const ImageList = TottalImages.map((Oneemage) => (
    <SwiperSlide>
      <img src={Oneemage.image} />
      {Oneemage.adresss !== "" ? (
        <h2 className="slider-text">
          <a href={Oneemage.caption}>
            <TextStyleAddress>
              <h5 align="center">{Oneemage.adresss}</h5>
            </TextStyleAddress>
          </a>
        </h2>
      ) : (
        ""
      )}
    </SwiperSlide>
  ));

  const numberOflength = TottalImages.length;

  return (
    <div>
      {numberOflength !== 0 ? (
        <div>
          <br></br>
          <h1>عزاء</h1>
          <div>
            <div className="funeral-container">
              <Swiper
                effect={"flip"}
                grabCursor={true}
                pagination={true}
                navigation={true}
                loop={true}
                autoplay={{
                  delay: 7000,
                  disableOnInteraction: false,
                }}
                className="mySwiper"
              >
                {ImageList}
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default observer(Funiral);
