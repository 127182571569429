import React from "react";
import { observer } from "mobx-react";
import { useState } from "react";
import imageSliderStore from "../stores/imageSliderStore";
import { TotalPicStyle, TextUnderImgThePrice } from "../styles";
import authStore from "../stores/authStore";
import UpdateVedioItemModel from "./models/UpdateVedioItemModel";

function Faz3aVedioItem({ imageSlider }) {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return (
    <div>
      <br></br>
      <TotalPicStyle>
        <iframe
          class="responsive-iframe"
          width="560"
          height="315"
          src={imageSlider.vedioLink}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </TotalPicStyle>
      <br></br>
      <TextUnderImgThePrice>{imageSlider.vedioTittle}</TextUnderImgThePrice>
      <TextUnderImgThePrice> {imageSlider.vedioCaption}</TextUnderImgThePrice>
      <div align="center">
        {authStore.user ? (
          <button
            class="btn btn-danger"
            align="center"
            onClick={() => imageSliderStore.deleteImageSlider(imageSlider.id)}
          >
            حذف{" "}
          </button>
        ) : (
          ""
        )}
        <div>
          {authStore.user ? (
            <button class="btn btn-primary" align="center" onClick={openModal}>
              تعديل
            </button>
          ) : (
            ""
          )}

          <UpdateVedioItemModel
            oldimage={imageSlider}
            isOpen={isOpen}
            closeModal={closeModal}
          />
        </div>
      </div>
      <h1>{""}</h1>
    </div>
  );
}

export default observer(Faz3aVedioItem);
