import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { observer } from "mobx-react";

import sandoqStore from "../stores/sandoqStore";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UpdateSandoq = ({ oldSandoq }) => {
  oldSandoq = oldSandoq || JSON.parse(localStorage.getItem("sandoq"));

  const classes = useStyles();

  const [sandoq, setSandoq] = React.useState(oldSandoq);

  const handleChange = (event) => {
    console.log(sandoq);
    setSandoq({ ...sandoq, [event.target.name]: event.target.value });
  };

  const handleSecondry = () => {
    setSandoq({
      ...sandoq,
      secondry: true,
      primary: false,
    });
  };
  const handlePrimary = () => {
    setSandoq({
      ...sandoq,
      primary: true,
      secondry: false,
    });
  };

  const handleDone = () => {
    if (sandoq.done === true) setSandoq({ ...sandoq, done: false });
    else setSandoq({ ...sandoq, done: true });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    sandoqStore.updateSandoq(sandoq, () => {
      window.location = "/sandoq";
    });
  };

  return (
    <Container flexDirection="rtl" component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          اضافة صندوق
        </Typography>
        <div align="center">
          <div align="center">
            الهدف اساسي
            <input
              type="radio"
              value="primary"
              name="Radio1"
              onChange={handlePrimary}
              checked={sandoq.primary}
            />
          </div>
          <div>
            الهدف ثانوي{" "}
            <input
              type="radio"
              value="secondry"
              name="Radio1"
              onChange={handleSecondry}
              checked={sandoq.secondry}
            />
          </div>
        </div>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="الهدف"
            name="goal"
            value={sandoq.goal}
            type="text"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={sandoq.reason}
            name="reason"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="اسباب عدم تحقق الهدف"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="ملاحظة"
            value={sandoq.note}
            name="note"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="done"
                value="remember"
                color="primary"
                onClick={handleDone}
                checked={sandoq.done}
              />
            }
            label=" يرجي تحديد الخانه اذا تم تحقيق الهدف
            "
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            اضافة
          </Button>
          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default observer(UpdateSandoq);
