import { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";

//styles

import { PlusRub } from "../../styles";

//component
import ProductModel from "../models/PictureModel";

const AddPictureBtn = ({ album }) => {
  //+++
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return (
    <div>
      <button onClick={openModal} type="button" className="btn btn-success">
        .اضافه صوره لالبوم {album.name}
      </button>
      <ProductModel isOpen={isOpen} closeModal={closeModal} album={album} />
    </div>
  );
};
export default AddPictureBtn;
