import { observer } from "mobx-react";
import { useState } from "react";
import { ItemImg } from "../styles";
import authStore from "../stores/authStore";
import imageSliderStore from "../stores/imageSliderStore";
import UpdateIimagesModel from "./models/UpdateIimagesModel";

const ItemSliderPic = ({ oneImage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return (
    <div>
      <ItemImg src={oneImage.image} />
      {oneImage.adresss}
      <br></br>
      {oneImage.caption}
      <h1>{""}</h1>
      <div align="center">
        {authStore.user ? (
          <div>
            <button
              class="btn btn-danger"
              align="center"
              onClick={() => imageSliderStore.deleteImageSlider(oneImage.id)}
            >
              <h6>حذف</h6>{" "}
            </button>
            <div>
              <button
                class="btn btn-primary"
                align="center"
                onClick={openModal}
              >
                <h6>تعديل</h6>{" "}
              </button>
              <UpdateIimagesModel
                oldimage={oneImage}
                isOpen={isOpen}
                closeModal={closeModal}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default observer(ItemSliderPic);
