import React from "react";
import imageSliderStore from "../stores/imageSliderStore";
import { observer } from "mobx-react";
import ItemSliderPic from "./ItemSliderPic";
import { DetailWrapperTest } from "../styles";
import productImage from "../stores/productImageStore";
import { Link } from "react-router-dom";
import { Redirect, useParams } from "react-router-dom";
import productStore from "../stores/productStore";

function EditeProducImages() {
  const productSlug = useParams().productSlug;

  const product = productStore.products.find(
    (_product) => _product.slug === productSlug
  );

//   if (!product) return <Redirect to="/alshattishop" />;
  //it we have just a product Id i want to trans to all product

  const imagesFromProductStore = product.productImages((productImage) =>
    product.getProductId(productImage.id)
  );

  const ImageList = imagesFromProductStore.map((oneImage) => (oneImage) => (
    <img arc={oneImage.image} />
  ));

  return (
    <div>
      {/* {authStore.user ? (
        <div>
          <div align="center">
            <Link to="/addsliderimage">
              <button type="button" class="btn btn-success">
                اضافة صورة
              </button>
            </Link>
          </div>
          <DetailWrapperTest>{ImageList}</DetailWrapperTest>{" "}
        </div>
      ) : (
        ""
      )} */}
      hhh
      {/* {ImageList} */}
    </div>
  );
}

export default observer(EditeProducImages);
