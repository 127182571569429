import React from "react";
import { observer } from "mobx-react";

function MemberItem({ member }) {
  return (
    <div>
      <div class="card text-center">
        <h2 style={{ color: "#004b49" }}>:الاسم</h2>
        <p class="card-text"> {member.name}</p>

        <h2 style={{ color: "#004b49" }}>:رقم الهاتف</h2>
        <p class="card-text"> {member.phonenumber} </p>
        <h2 style={{ color: "#004b49" }}>:العنوان</h2>
        <p class="card-text"> {member.address} </p>
      </div>
    </div>
  );
}

export default observer(MemberItem);
