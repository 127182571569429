import React from "react";
import { observer } from "mobx-react";
import contactStore from "../stores/contactStore";

function MassageItem({ contact }) {
  const HandleSubmet = () => {
    contactStore.updateContact({ ...contact, done: true });
    alert("تم الرد");
  };

  return (
    <div>
      <div class="card text-center">
        <h2 style={{ color: "#004b49" }}>:الاسم</h2>
        <p class="card-text"> {contact.name}</p>
        <h2 style={{ color: "#004b49" }}>:الايميل</h2>
        <p class="card-text"> {contact.email} </p>
        <h2 style={{ color: "#004b49" }}>:رقم الهاتف</h2>
        <p class="card-text"> {contact.phonenumber} </p>
        <h2 style={{ color: "#004b49" }}>:الرسالة</h2>
        <p class="card-text"> {contact.text} </p>
        <div>
          <button
            class="btn btn-danger"
            align="center"
            onClick={() => contactStore.deleteContact(contact.id)}
          >
            حذف{" "}
          </button>
        </div>
        <h1>{""}</h1>
        <button class="btn btn-primary" onClick={HandleSubmet}>
          تم الرد{" "}
        </button>
      </div>
    </div>
  );
}

export default observer(MassageItem);
