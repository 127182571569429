import React from "react";
import { observer } from "mobx-react";

import Container from "@material-ui/core/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./socail.scss";
const BottomNavBar = () => {
  return (
    <div style={{ backgroundColor: "white", marginTop: "30px" }}>
      <footer>
        <Container component="main" maxWidth="s" align="center">
          <Row>
            <Col>
              <div className="container">
                <div className="social-buttons">
                  <a
                    href="https://www.instagram.com/shattamim/"
                    target="blank"
                    class="social-margin"
                  >
                    <div className="social-icon linkedin">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </div>
                  </a>

                  <a
                    href="https://m.facebook.com/shababalshatti"
                    target="blank"
                    className="social-margin"
                  >
                    <div className="social-icon facebook">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </div>
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UCKX0f0v6nZ9YbyXZHpI8s8w"
                    target="blank"
                    class="social-margin"
                  >
                    <div className="social-icon youtube">
                      <i className="fa fa-youtube" aria-hidden="true"></i>
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/shababalshatti/"
                    target="blank"
                    class="social-margin"
                  >
                    <div className="social-icon linkedin">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container component="main" maxWidth="s" align="center">
          <Row>
            <Col>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
              >
                © جميع الحقوق محفوظة لدي موقع عائلة الشطي
              </div>
              <br></br>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default observer(BottomNavBar);
