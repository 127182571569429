import React, { Component, useState } from "react";
import "../App.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Modal from "react-bootstrap/Modal";

import Grid from "@material-ui/core/Grid";
import formStore from "../stores/formStore";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};
const FormPage = () => {
  const [form, setForm] = useState({
    name: "",
    birthday: "",
    civilId: "",
    houseNumber: "",
    address: "",
    mobile: "",
    mobile2: "",
    mobile: "",
    email: "",
    fax: "",
    twitter: "",
    instagram: "",
    facebook: "",
    organisation: "",
    Department: "",
    sh3ba: "",
    jobTitle: "",
    qesm: "",

    commercialField: "",
    NameOfStore: "",
    AdressOfStore: "",
    PhoneNumberOfStore: "",
    numberofKids: null,
    NameOfKid1: "",
    BirthdayOfKid1: "",
    levelOfEducationKid1: "",

    NameOfKid2: "",
    BirthdayOfKid2: "",
    levelOfEducationKid2: "",

    NameOfKid3: "",
    BirthdayOfKid3: "",
    levelOfEducationKid3: "",

    NameOfKid4: "",
    BirthdayOfKid4: "",
    levelOfEducationKid4: "",

    NameOfKid5: "",
    BirthdayOfKid5: "",
    levelOfEducationKid5: "",

    NameOfKid6: "",
    BirthdayOfKid6: "",
    levelOfEducationKid6: "",

    NameOfKid7: "",
    BirthdayOfKid7: "",
    levelOfEducationKid7: "",

    Done: false,
    slug: "form",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleChange = (event) => {
    console.log(form);
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      form.name.length !== 0 &&
      form.birthday.length !== 0 &&
      form.mobile.length !== 0 &&
      form.address.length !== 0 &&
      form.jobTitle.length !== 0 &&
      form.organisation.length !== 0 &&
      form.NameOfKid1.length !== 0 &&
      form.BirthdayOfKid1.length !== 0
    ) {
      formStore.creatForm(form);
      setForm({
        name: "",
        birthday: "",
        civilId: "",
        houseNumber: "",
        address: "",
        mobile: "",
        mobile2: "",
        mobile: "",
        email: "",
        fax: "",
        twitter: "",
        instagram: "",
        facebook: "",
        organisation: "",
        Department: "",
        sh3ba: "",
        jobTitle: "",
        qesm: "",

        commercialField: "",
        NameOfStore: "",
        AdressOfStore: "",
        PhoneNumberOfStore: "",
        numberofKids: 0,
        NameOfKid1: "",
        BirthdayOfKid1: "",
        levelOfEducationKid1: "",

        NameOfKid2: "",
        BirthdayOfKid2: "",
        levelOfEducationKid2: "",

        NameOfKid3: "",
        BirthdayOfKid3: "",
        levelOfEducationKid3: "",

        NameOfKid4: "",
        BirthdayOfKid4: "",
        levelOfEducationKid4: "",

        NameOfKid5: "",
        BirthdayOfKid5: "",
        levelOfEducationKid5: "",

        NameOfKid6: "",
        BirthdayOfKid6: "",
        levelOfEducationKid6: "",

        NameOfKid7: "",
        BirthdayOfKid7: "",
        levelOfEducationKid7: "",
      });
      setShow2(true);
    } else if (
      form.name.length === 0 ||
      form.birthday.length === 0 ||
      form.mobile.length === 0 ||
      form.address.length === 0 ||
      form.jobTitle.length === 0 ||
      form.organisation.length === 0 ||
      form.NameOfKid1.length === 0 ||
      form.BirthdayOfKid1.length === 0
    ) {
      handleShow();
    }
  };

  return (
    <div className="wrapper">
      <div className="form-wrapper">
        <h1>نموذج جمع بيانات العائلةالشطي</h1>

        <form onSubmit={handleSubmit} noValidate>
          {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="fullName"
                label="الاسم الرباعي"
                // onChange={handleChange}
                autoComplete="off"
              />
            </Grid> */}

          <div className="fullName">
            <label htmlFor="fullName">*الاسم الخماسي</label>
            <input
              // className={formErrors.fullName.length > 0 ? "error" : null}
              placeholder="الاسم الخماسي"
              type="text"
              value={form.name}
              name="name"
              noValidate
              onChange={handleChange}
            />
            {/* {formErrors.fullName.length > 0 && (
              <span className="errorMessage">{formErrors.fullName}</span>
            )} */}
          </div>
          <div className="fullName">
            <label htmlFor="fullName">(الرقم المدني (اختياري</label>
            <input
              placeholder="الرقم المدني"
              type="text"
              name="civilId"
              value={form.civilId}
              noValidate
              onChange={handleChange}
            />
          </div>
          {/* <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date picker dialog"
              format="MM/dd/yyyy"
              // value={selectedDate}
          onChange={handleChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            /> */}

          {/* </Wrapper> */}
          <div className="fullName">
            <label htmlFor="fullName">*تاريخ الميلاد</label>
            <input
              // className={formErrors.mobile.length > 0 ? "error" : null}

              format={"DD-MM-YYYY"}
              label="my date"
              inputVariant="outlined"
              placeholder="تاريخ الميلاد"
              type="date"
              name="birthday"
              value={form.birthday}
              format="MM/dd/yyyy"
              noValidate
              id="date"
              onChange={handleChange}
            />
            {/* {formErrors.date.length < 0 && (
              <span className="errorMessage">{formErrors.date}</span>
            )} */}
          </div>

          <div className="address">
            <label htmlFor="address"> *العنوان</label>
            <input
              // className={formErrors.address.length > 0 ? "error" : null}
              placeholder="العنوان"
              type="text"
              name="address"
              value={form.address}
              noValidate
              onChange={handleChange}
            />
            {/* {formErrors.address.length > 0 && (
              <span className="errorMessage">{formErrors.address}</span>
            )} */}
          </div>

          <div className="mobilegroub">
            <label htmlFor="mobilegroub">هاتف المنزل</label>
            <input
              placeholder="هاتف المنزل"
              value={form.houseNumber}
              type="text"
              name="houseNumber"
              value={form.houseNumber}
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">2 رقم الهاتف</label>
            <input
              placeholder="2 رقم الهاتف"
              type="text"
              name="mobile2"
              value={form.mobile2}
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">*رقم الهاتف</label>
            <input
              // className={formErrors.mobile.length > 0 ? "error" : null}
              placeholder="رقم الهاتف"
              type="text"
              value={form.mobile}
              name="mobile"
              noValidate
              onChange={handleChange}
            />
            {/* {formErrors.mobile.length > 0 && (
              <span className="errorMessage">{formErrors.mobile}</span>
            )} */}
          </div>

          <div className="email">
            <label htmlFor="email">الايميل</label>
            <input
              // className={formErrors.email.length > 0 ? "error" : null}
              placeholder="الايميل"
              type="email"
              name="email"
              value={form.email}
              noValidate
              onChange={handleChange}
            />
            {/* {formErrors.email.length < 0 && (
              <span className="errorMessage">{formErrors.email}</span>
            )} */}
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">فاكس</label>
            <input
              placeholder="فاكس"
              type="text"
              value={form.fax}
              name="fax"
              noValidate
              onChange={handleChange}
            />
          </div>
          <h3
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "30px",
              backgroundColor: "#484848	",
              color: "white",
              // fontWeight: "lighter",
            }}
          >
            وسائل التواصل
          </h3>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">تويتر</label>
            <input
              placeholder="تويتر"
              type="text"
              value={form.twitter}
              name="twitter"
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">فيسبوك</label>
            <input
              placeholder="فيسبوك"
              value={form.facebook}
              type="text"
              name="facebook"
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">انستقرام</label>
            <input
              placeholder=" انستقرام"
              type="text"
              name="instagram"
              value={form.instagram}
              noValidate
              onChange={handleChange}
            />
          </div>
          <h3
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "30px",
              backgroundColor: "#484848	",
              color: "white",
              // fontWeight: "lighter",
            }}
          >
            العمل
          </h3>

          <div className="mobile">
            <label htmlFor="mobile">الإدارة</label>
            <input
              placeholder="الإدارة"
              value={form.Department}
              type="text"
              name="Department"
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">*الوزارة</label>
            <input
              placeholder="الوزارة"
              type="text"
              value={form.organisation}
              name="organisation"
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="address">
            <label htmlFor="mobile">*المسمى الوظيفي</label>
            <input
              placeholder="المسمى الوظيفي"
              type="text"
              value={form.jobTitle}
              name="jobTitle"
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">الشعبة</label>
            <input
              placeholder="الشعبة"
              value={form.sh3ba}
              type="text"
              name="sh3ba"
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">القسم</label>
            <input
              placeholder="القسم"
              type="text"
              name="qesm"
              value={form.qesm}
              noValidate
              onChange={handleChange}
            />
          </div>
          <h3
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "30px",
              backgroundColor: "#484848	",
              color: "white",
              // fontWeight: "lighter",
            }}
          >
            العمل التجاري
          </h3>

          <div className="address">
            <label htmlFor="mobile">المجال التجاري</label>
            <input
              placeholder="المجال التجاري"
              type="text"
              value={form.commercialField}
              value={form.commercialField}
              name="commercialField"
              noValidate
              onChange={handleChange}
            />
          </div>

          <div className="mobile">
            <label htmlFor="mobile">رقم الهاتف</label>
            <input
              placeholder="رقم الهاتف"
              value={form.PhoneNumberOfStore}
              type="text"
              name="PhoneNumberOfStore"
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">اسم المشروع</label>
            <input
              placeholder="اسم المشروع"
              value={form.NameOfStore}
              type="text"
              name="NameOfStore"
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="address">
            <label htmlFor="mobile">حساب الانستقرام</label>
            <input
              placeholder="حساب الانستقرام"
              type="text"
              value={form.instagramBusiness}
              name="instagramBusiness"
              noValidate
              onChange={handleChange}
            />
          </div>

          <div className="address">
            <label htmlFor="mobile">العنوان</label>
            <input
              placeholder="العنوان"
              type="text"
              value={form.AdressOfStore}
              name="AdressOfStore"
              noValidate
              onChange={handleChange}
            />
          </div>

          <h3
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "30px",
              backgroundColor: "#484848	",
              color: "white",
              // fontWeight: "lighter",
            }}
          >
            الأبناء- إناث وذكور
          </h3>
          <div className="address">
            <label htmlFor="mobile">*عدد الابناء</label>
            <input
              placeholder="عدد الابناء"
              value={form.numberofKids}
              type="number"
              name="numberofKids"
              min={1}
              noValidate
              onChange={handleChange}
            />
          </div>

          <div className="mobilegroub">
            <label htmlFor="mobilegroub">المرحلةالدراسية</label>

            <input
              type="text"
              value={form.levelOfEducationKid1}
              name="levelOfEducationKid1"
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <label htmlFor="mobilegroub">تاريخ *الميلاد-مطلوب</label>

            <input
              placeholder="MM/dd/yyyy"
              type="date"
              value={form.BirthdayOfKid1}
              name="BirthdayOfKid1"
              format="MM/dd/yyyy"
              noValidate
              id="date"
              onChange={handleChange}
            />
          </div>

          <div className="mobilegroub">
            <label htmlFor="mobilegroub">الاسم- مطلوب</label>

            <input
              type="text"
              name="NameOfKid1"
              noValidate
              placeholder="الابن الاول"
              value={form.NameOfKid1}
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="levelOfEducationKid2"
              value={form.levelOfEducationKid2}
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              placeholder="MM/dd/yyyy"
              type="date"
              name="BirthdayOfKid2"
              value={form.BirthdayOfKid2}
              format="MM/dd/yyyy"
              noValidate
              id="date"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="NameOfKid2"
              value={form.NameOfKid2}
              noValidate
              placeholder="الابن الثاني"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="levelOfEducationKid3"
              value={form.levelOfEducationKid3}
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              placeholder="MM/dd/yyyy"
              type="date"
              name="BirthdayOfKid3"
              value={form.BirthdayOfKid3}
              format="MM/dd/yyyy"
              noValidate
              id="date"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="NameOfKid3"
              value={form.NameOfKid3}
              noValidate
              placeholder="الابن الثالث"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="levelOfEducationKid4"
              value={form.levelOfEducationKid4}
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              placeholder="MM/dd/yyyy"
              type="date"
              name="BirthdayOfKid4"
              value={form.BirthdayOfKid4}
              format="MM/dd/yyyy"
              noValidate
              id="date"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="NameOfKid4"
              value={form.NameOfKid4}
              noValidate
              placeholder="الابن الرابع"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="levelOfEducationKid5"
              value={form.levelOfEducationKid5}
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              placeholder="MM/dd/yyyy"
              type="date"
              name="BirthdayOfKid5"
              value={form.BirthdayOfKid5}
              format="MM/dd/yyyy"
              noValidate
              id="date"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="NameOfKid5"
              value={form.NameOfKid5}
              noValidate
              placeholder="الابن الخامس"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="levelOfEducationKid6"
              value={form.levelOfEducationKid6}
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              placeholder="MM/dd/yyyy"
              type="date"
              name="BirthdayOfKid6"
              value={form.BirthdayOfKid6}
              format="MM/dd/yyyy"
              noValidate
              id="date"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="NameOfKid6"
              value={form.NameOfKid6}
              noValidate
              placeholder="الابن السادس"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="levelOfEducationKid7"
              value={form.levelOfEducationKid7}
              noValidate
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              placeholder="MM/dd/yyyy"
              type="date"
              name="BirthdayOfKid7"
              value={form.BirthdayOfKid7}
              format="MM/dd/yyyy"
              noValidate
              id="date"
              onChange={handleChange}
            />
          </div>
          <div className="mobilegroub">
            <input
              type="text"
              name="NameOfKid7"
              value={form.NameOfKid7}
              noValidate
              placeholder="الابن السابع"
              onChange={handleChange}
            />
          </div>

          <div className="createAccount">
            <button type="submit">ارسال</button>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>تنبية</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              الرجاء التأكد من تعبئة جميع الخانات المطلوبة *
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <>
            <Modal
              show={show2}
              onHide={() => setShow2(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>شكراً لوقتك، لقد تم ارسال البيانات</p>
              </Modal.Body>
            </Modal>
          </>
        </form>
      </div>
    </div>
  );
};
export default FormPage;

// const classes = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   textField: {
//     marginLeft: theme.spacing(2),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
//   button: {
//     display: "block",
//     marginTop: theme.spacing(2),
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
// }));
