import React from "react";
import { observer } from "mobx-react";
import MemberItem from "./MemberItem";
import sandoqStore from "../stores/sandoqStore";

function Contactedmembers() {
  const Not = sandoqStore.sandoqs.filter(
    (oneSandoq) => oneSandoq.doneContact === true
  );

  const MemberList = Not.map((member) => (
    <MemberItem member={member} memberId={member.id} />
  ));

  const MembersLength = MemberList.length;

  return (
    <div>
      <div class="card text-center">
        <div class="card-header" style={{ backgroundColor: "grey" }}>
          <h2>({MembersLength})عددالأعضاء - العدد</h2>
        </div>
        <div style={{ backgroundColor: "grey" }}> {MemberList}</div>
      </div>
    </div>
  );
}

export default observer(Contactedmembers);
