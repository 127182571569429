import React from "react";
import { WhatsApp } from "@material-ui/icons";

function WhatsupLink() {
  return (
    <div align="center">
      <br></br>

      <a href="https://wa.me/97555913" target="_blank">
        للطلب يرجى التواصل عبر ارسال المنتجات المطلوبه عن طريق الوتساب الى الرقم
        التالي 97555913
      </a>
      <WhatsApp />
    </div>
  );
}

export default WhatsupLink;
