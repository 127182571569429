import React from "react";
import familyTree from "../familyTree.JPG";
function FamilyTree() {
  return (
    <div style={{ backgroundImage: `url(${familyTree})`, width: "100%" }}>
      <img src={familyTree} width="100%" alt="family tree" />
    </div>
  );
}

export default FamilyTree;
