import dewanStore from "../stores/dewanStore";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AddDewan = () => {
  const classes = useStyles();

  const [dewan, setDewan] = useState({
    image: "",
    name: "",
    phoneNumber: "",
    fatherOf: "",
    address: "",
    googleMaps: "",
    note: "",
    sat: false,
    sun: false,
    mon: false,
    tue: false,
    thu: false,
    wen: false,
    fri: false,
  });

  const handleChange = (event) => {
    console.log(dewan);
    setDewan({ ...dewan, [event.target.name]: event.target.value });
  };

  const handleImg = (event) => {
    setDewan({ ...dewan, image: event.target.files[0] });
  };

  const handleSat = () => {
    if (dewan.sat === true) setDewan({ ...dewan, sat: false });
    else setDewan({ ...dewan, sat: true });
  };

  const handleSun = () => {
    if (dewan.sun === true) setDewan({ ...dewan, sun: false });
    else setDewan({ ...dewan, sun: true });
  };

  const handleMon = () => {
    if (dewan.mon === true) setDewan({ ...dewan, mon: false });
    else setDewan({ ...dewan, mon: true });
  };

  const handleTue = () => {
    if (dewan.tue === true) setDewan({ ...dewan, tue: false });
    else setDewan({ ...dewan, tue: true });
  };

  const handleWen = () => {
    if (dewan.wen === true) setDewan({ ...dewan, wen: false });
    else setDewan({ ...dewan, wen: true });
  };

  const handleThu = () => {
    if (dewan.thu === true) setDewan({ ...dewan, thu: false });
    else setDewan({ ...dewan, thu: true });
  };

  const handleFri = () => {
    if (dewan.fri === true) setDewan({ ...dewan, fri: false });
    else setDewan({ ...dewan, fri: true });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    dewanStore.creatDewan(dewan, () => {
      window.location = "/dalel";
    });
  };

  return (
    <Container flexDirection="rtl" component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          اضافة ديوانية
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="الاسم"
            name="name"
            value={dewan.name}
            type="text"
            onChange={handleChange}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={dewan.fatherOf}
            name="fatherOf"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="الكنية"
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={dewan.note}
            name="note"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="ملاحظة"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            label="موقع خريطة قوقل"
            value={dewan.googleMaps}
            name="googleMaps"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="العنوان"
            value={dewan.address}
            name="address"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            label="رقم الهاتف"
            value={dewan.phoneNumber}
            name="phoneNumber"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
          />

          <label> الصورة الشخصية</label>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            name="image"
            onChange={handleImg}
            type="file"
            flexDirection="rtl"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="sat"
                value="remember"
                color="primary"
                onClick={handleSat}
                checked={dewan.sat}
              />
            }
            label="السبت"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="sun"
                value="remember"
                color="primary"
                onClick={handleSun}
                checked={dewan.sun}
              />
            }
            label="الاحد"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="mon"
                value="remember"
                color="primary"
                onClick={handleMon}
                checked={dewan.mon}
              />
            }
            label="الاثنين"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="tue"
                value="remember"
                color="primary"
                onClick={handleTue}
                checked={dewan.tue}
              />
            }
            label="الثلاثاء"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="wen"
                value="remember"
                color="primary"
                onClick={handleWen}
                checked={dewan.wen}
              />
            }
            label="الاربعاء"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="thu"
                value="remember"
                color="primary"
                onClick={handleThu}
                checked={dewan.thu}
              />
            }
            label="الخميس"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="fri"
                value="remember"
                color="primary"
                onClick={handleFri}
                checked={dewan.fri}
              />
            }
            label="الجمعة"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            اضافة
          </Button>

          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default observer(AddDewan);
