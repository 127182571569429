import React from "react";
import faz3aStore from "../stores/faz3aStore";
import authStore from "../stores/authStore";
import Faz3aModel from "./models/Faz3aModel";
import { useState } from "react";

function Faz3aItem({ faz3a }) {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return (
    <div>
      <div class="card text-center">
        <div class="card-header">{faz3a.jam3ya}</div>
        <div class="card-body">
          <h5 class="card-title">{faz3a.tittle}</h5>

          <img
            src={faz3a.image}
            class="img-fluid"
            alt="faz3a"
            width="400px"
            height="300px"
          />
          <p class="card-text">{faz3a.description}</p>
          <a href={faz3a.link} class="btn btn-primary">
            {faz3a.link} : رابط الدفع{" "}
          </a>
          <div align="center">
            {authStore.user ? (
              <div>
                <br></br>
                <button
                  class="btn btn-danger"
                  align="center"
                  onClick={() => faz3aStore.deleteFaz3a(faz3a.id)}
                >
                  حذف{" "}
                </button>
              </div>
            ) : (
              ""
            )}
            <div>
              {authStore.user ? (
                <div>
                  <br></br>
                  <button
                    class="btn btn-primary"
                    align="center"
                    onClick={openModal}
                  >
                    تعديل
                  </button>
                  <Faz3aModel
                    ـfaz3a={faz3a}
                    isOpen={isOpen}
                    closeModal={closeModal}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faz3aItem;
