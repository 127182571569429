import { makeAutoObservable } from "mobx";
import instance from "./instance";

class Faz3aStore {
  faz3as = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getfaz3aId = (faz3aId) => this.faz3as.find((faz3a) => faz3a.id === faz3aId);

  fetchFaz3as = async () => {
    try {
      const response = await instance.get("/faz3a");
      this.faz3as = response.data;
      this.loading = false;
    } catch (error) {
      console.error("faz3a -> fetchFaz3as -> error", error);
    }
  };

  creatFaz3a = async (newFaz3a, cb) => {
    try {
      const formData = new FormData();
      for (const key in newFaz3a) formData.append(key, newFaz3a[key]);

      const response = await instance.post("/faz3a", formData);
      this.faz3as.push(response.data);
      cb();
    } catch (error) {
      console.error(console.error);
    }
  };
  updateFaz3a = async (updatedFaz3a, cb) => {
    try {
      const formData = new FormData();

      for (const key in updatedFaz3a) formData.append(key, updatedFaz3a[key]);
      console.log(updatedFaz3a.id);
      await instance.put(`/faz3a/${updatedFaz3a.id}`, formData);

      const faz3a = this.faz3as.find((faz3a) => faz3a.id === updatedFaz3a.id);
      for (const key in updatedFaz3a) faz3a[key] = updatedFaz3a[key];
      faz3a.image = URL.createObjectURL(updatedFaz3a.image);
      cb();
    } catch (error) {
      console.error(
        "🚀 ~ file: faz3aStore.js ~ line 35 ~ Faz3aStore ~ updateFaz3a ~ error",
        error
      );
    }
  };
  deleteFaz3a = async (faz3aId) => {
    try {
      const r = window.confirm(" هل أنت متأكد من عملية الحذف  ?");

      if (r == true) {
        await instance.delete(`/faz3a/${faz3aId}`);

        this.faz3as = this.faz3as.filter((faz3a) => faz3a.id !== faz3aId);
      } else {
        alert("لقد تم الغاء العملية!");
      }
    } catch (error) {
      console.log("GoalsStore -> deleteFaz3a -> error", error);
    }
  };
}

const faz3aStore = new Faz3aStore();
faz3aStore.fetchFaz3as();
export default faz3aStore;
