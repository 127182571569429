import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import sms from "../sms.JPG";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import contactStore from "../stores/contactStore";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import Modal from "react-bootstrap/Modal";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    direction: "center",
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const ContactUs = () => {
  const classes = useStyles();
  const [contact, setContact] = useState({
    name: "",
    phonenumber: "",
    text: "",
    email: "",
    done: false,
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleChange = (event) => {
    setContact({ ...contact, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      contact.name.length !== 0 &&
      contact.text.length !== 0 &&
      contact.phonenumber.length !== 0
    ) {
      contactStore.creatContact(contact);
      setContact({
        name: "",
        phonenumber: "",
        text: "",
        email: "",
      });
      setShow2(true);
    } else if (
      contact.name.length === 0 ||
      contact.text.length === 0 ||
      contact.phonenumber.length === 0
    ) {
      handleShow();
    }
  };

  return (
    <div class="container">
      <div>
        <div>
          <br></br>
          <Container
            component="main"
            maxWidth="xs"
            style={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 10px 50px #555",
              backgroundColor: "#ffffff",
            }}
          >
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                تواصل معنا
              </Typography>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="name"
                      value={contact.name}
                      label="الاسم الرباعي"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="email"
                      label="البريد الالكتروني"
                      value={contact.email}
                      autoComplete="off"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      required
                      fullWidth
                      value={contact.phonenumber}
                      name="phonenumber"
                      label="رقم الهاتف"
                      flexDirection="rtl"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} backgroundColor="white">
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="outlined-multiline-flexible"
                      autoComplete="off"
                      name="text"
                      label="محتوى الرسالة"
                      value={contact.text}
                      onChange={handleChange}
                      multiline
                    />
                  </Grid>
                </Grid>

                <>
                  <Button
                    type="submit"
                    onSubmit={handleSubmit}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSubmit}
                  >
                    ارسال
                  </Button>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>تنبية</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>الرجاء التأكد من تعبئة جميع الخانات</Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                </>

                <>
                  <Modal
                    show={show2}
                    onHide={() => setShow2(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>لقد تم ارسال رسالتك، سيتم التواصل معك في اقرب وقت</p>
                    </Modal.Body>
                  </Modal>
                </>

                <Grid container justify="flex-end">
                  <Grid item></Grid>
                </Grid>
              </form>
            </div>
          </Container>
        </div>
        <div align="center"></div>
        <div>
          <br></br>
          <br></br>
          <Container
            component="main"
            maxWidth="xs"
            style={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 10px 50px #555",
              backgroundColor: "#ffffff",
            }}
          >
            <CssBaseline />
            <div>
              <img
                src={sms}
                class="img-fluid"
                alt="Responsive pic"
                width="450px"
                height="150px"
              />
            </div>
            <Typography component="h1" variant="h6" align="right">
              :لأي استفسار وللاقتراحات يمكنك التواصل معنا عبر
            </Typography>
            <text>
              <br />
            </text>
            <Typography align="right" component="h4" variant="h6">
              :البريد الالكتروني @
              <text align="center">
                <br />
              </text>
              <a href="mailto:info@alshatti.net"> info@alshatti.net</a>
            </Typography>
            <text>
              <br />
              <br />
            </text>

            <div>
              <Typography component="h4" variant="h6" align="right">
                :حسابات الانستقرام
                {"   "}
                <FontAwesomeIcon
                  icon={faInstagram}
                  align="right"
                  size="1x"
                  color="black"
                />
                <text align="right">
                  <br />
                </text>
                <a
                  align="right"
                  href="https://www.instagram.com/shababalshatti/"
                  className="instagram social"
                >
                  @shababalshatti
                </a>
                <text>
                  <br />
                </text>
                <a
                  align="right"
                  href="https://www.instagram.com/shattamim/"
                  className="instagram social"
                >
                  @shattamim
                  <br></br>
                </a>
                <br></br>
              </Typography>
            </div>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}></Grid>
            </form>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default observer(ContactUs);
