import albumStore from "../stores/albumStore";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const AddAlbum = () => {
  const classes = useStyles();

  const [album, setalbum] = useState({
    image: "",
    slugcreator: "album",
  });

  const handleChange = (event) => {
    console.log(album);
    setalbum({ ...album, [event.target.name]: event.target.value });
  };

  //   const handleImg = (event) => {
  //     setalbum({ ...album, image: event.target.files[0] });
  //   };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(album);
    console.log(handleSubmit);
    albumStore.creatAlbum(album, () => {
      window.location = "/album";
    });
  };

  return (
    <Container flexDirection="rtl" component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          اضافه البوم
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={album.name}
            name="name"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="اسم الالبوم"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            اضافة
          </Button>

          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default observer(AddAlbum);
