import { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";

//styles

import { PlusRub } from "../../styles";
import ProductPicModel from "../models/ProductPicModel";
//component

const AddproductImagBtn = ({ product }) => {
  //+++
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return (
    <div>
      <button onClick={openModal} type="button" className="btn btn-success">
        اضافه صوره لمنتج {product.name}
      </button>
      <ProductPicModel
        isOpen={isOpen}
        closeModal={closeModal}
        product={product}
      />
    </div>
  );
};
export default AddproductImagBtn;
