import Modal from "react-modal";
import { useState } from "react";

//styles
import { CreateButtonStyled } from "../../styles";

import productStore from "../../stores/productStore";

const ProductModel = ({ isOpen, closeModal, oldProduct }) => {
  const [product, setProduct] = useState(oldProduct);

  const handleChange = (event) => {
    setProduct({ ...product, [event.target.name]: event.target.value });
  };
  const handleImg = (event) => {
    setProduct({ ...product, image: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    closeModal();
    productStore.updateProduct(product);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="product Modal"
    >
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h3 align="right"> تعديل المنتج </h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group row">
          <div className="col-12">
            <h3 align="right">اسم المنتج</h3>
            <input
              value={product.name}
              name="name"
              onChange={handleChange}
              type="text"
              className="form-control"
            />
          </div>

          <div className="col-12">
            <h3 align="right">Price</h3>
            <input
              value={product.price}
              name="price"
              onChange={handleChange}
              type="number"
              min="1"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-12">
          <h3 align="right">Description</h3>
          <input
            value={product.description}
            name="description"
            onChange={handleChange}
            type="text"
            className="form-control"
          />
        </div>

        <div className="col-12">
          <h3 align="right">Image</h3>
          <input
            name="img"
            onChange={handleImg}
            type="file"
            className="form-control"
          />
        </div>

        <p></p>

        <CreateButtonStyled>تعديل</CreateButtonStyled>
      </form>
    </Modal>
  );
};

export default ProductModel;
