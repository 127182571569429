import React from "react";
import faz3aStore from "../stores/faz3aStore";
import Faz3aItem from "./Faz3aItem";
import { Link } from "react-router-dom";
import authStore from "../stores/authStore";

import { observer } from "mobx-react";

function Faz3aList() {
  const Faz3aTottal = faz3aStore.faz3as.map((faz3a) => (
    <Faz3aItem faz3a={faz3a} />
  ));
  return (
    <div style={{ backgroundColor: "white" }}>
      <div>
        {authStore.user ? (
          <Link to="/Addfaz3a">
            <div align="center" padding="0.9em">
              <button type="button" class="btn btn-success">
                اضافة مشروع جديد
              </button>
            </div>
          </Link>
        ) : (
          ""
        )}
      </div>
      <h1 align="center">المشاريع الخيرية</h1>
      <br></br>
      <Link to="/oldfaz3a">
        <button type="button" class="btn btn-dark btn-lg btn-block">
          المشاريع السابقة
        </button>
      </Link>
      <br></br>
      {Faz3aTottal}
    </div>
  );
}
export default observer(Faz3aList);
