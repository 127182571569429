import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react";

import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";

import Home from "./Home";
import AllDewan from "./AllDewan";
import AllBusiness from "./AllBusiness";
import Signin from "./Signin";
import AllSLiderHomePic from "./AllSLiderHomePic";
import AlshattiShop from "./AlshattiShop";
import Sandooq from "./Sandooq";
import AddDewan from "./AddDewan";
import authStore from "../stores/authStore";
import Faz3aList from "./Faz3aList";
import AddFaz3a from "./AddFaz3a";
import OldFaz3a from "./OldFaz3a";
import ImageSliderHomeModel from "./models/ImageSliderHomeModel";
import EditAboutUs from "./EditAboutUs";
import AllSLiderFaz3aPic from "./AllSLiderFaz3aPic";
import ImageSliderFaz3aModel from "./models/ImageSliderFaz3aModel";
import Mathon from "./Mathon";
import Lawyer from "./Lawyer";
import LawyerModel from "./models/LawyerModel";
import MathonModel from "./models/MathonModel";
import DoctorModel from "./models/DoctorModel";
import Doctor from "./Doctor";
import Others from "./Others";
import OtherModel from "./models/OtherModel";
import Massages from "./Massages";
import AddBusiness from "./AddBusiness";
import UpdateBusiness from "./UpdateBusiness";
import DoneMassages from "./DoneMassages";
import UpdateDewan from "./UpdateDewan";
import AddSandoq from "./AddSandoq";
import UpdateSandoq from "./UpdateSandoq";
import Members from "./Members";
import Contactedmembers from "./Contactedmembers";
import UpdateHomeInfo from "./UpdateHomeInfo";
import AddFaz3aVedio from "./AddFaz3aVedio";
import FamilyTree from "./FamilyTree";
import AllProducts from "./AllProducts";
import DetailProduct from "./DetailProduct";
import AddProduct from "./AddProduct";
import EditeProducImages from "./EditeProducImages";
import Album from "./Album";
import DetailALbum from "./DetailALbum";
import FormPage from "./FormPage";
import AddAlbum from "./AddAlbum";
import Add3azaPicModel from "./models/Add3azaPicModel";
import AddWeddingPicModel from "./models/AddWeddingPicModel";
import AllForm from "./AllForm";
import DetailForm from "./DetailForm";

const Routes = () => {
  return (
    <Switch>
      {authStore.user ? (
        <Route path="/editaboutus">
          <EditAboutUs />
        </Route>
      ) : (
        ""
      )}
      {authStore.user ? (
        <Route path="/editsliderimg">
          <AllSLiderFaz3aPic />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/Addsliderimg">
          <ImageSliderFaz3aModel />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/Addlawyer">
          <LawyerModel />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/Addmathon">
          <MathonModel />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/Adddoctor">
          <DoctorModel />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/Addsandoq">
          <AddSandoq />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/updatesandoq">
          <UpdateSandoq />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/Addothers">
          <OtherModel />
        </Route>
      ) : (
        ""
      )}
      {authStore.user ? (
        <Route path="/Addvideo">
          <AddFaz3aVedio />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/editsliderimage">
          <AllSLiderHomePic />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/addsliderimage">
          <ImageSliderHomeModel />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/addfurnalimage">
          <Add3azaPicModel />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/addWeddingimage">
          <AddWeddingPicModel />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/addDewan">
          <AddDewan />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/updateDewan">
          <UpdateDewan />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/editeproducimages">
          <EditeProducImages />
        </Route>
      ) : (
        ""
      )}
      {authStore.user ? (
        <Route path="/addfaz3a">
          <AddFaz3a />
        </Route>
      ) : (
        ""
      )}
      {authStore.user && authStore.user.username === "shattamimK" ? (
        <Route path="/members">
          <Members />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/donemassages">
          <DoneMassages />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/addbusiness">
          <AddBusiness />
        </Route>
      ) : (
        ""
      )}

      {authStore.user && authStore.user.username === "shattamimK" ? (
        <Route path="/contacedmembers">
          <Contactedmembers />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/updatebusiness">
          <UpdateBusiness />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/updatehomeinfo">
          <UpdateHomeInfo />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/addproduct">
          <AddProduct />
        </Route>
      ) : (
        ""
      )}

      {authStore.user ? (
        <Route path="/addalbum">
          <AddAlbum />
        </Route>
      ) : (
        ""
      )}

      {authStore.user && authStore.user.username === "shattamimK" ? (
        <Route path="/allforms">
          <AllForm />
        </Route>
      ) : (
        ""
      )}
      {authStore.user && authStore.user.username === "shattamimK" ? (
        <Route path="/forms/:formSlug">
          <DetailForm />
        </Route>
      ) : (
        ""
      )}

      <Route path="/aboutus">
        <AboutUs />
      </Route>
      <Route path="/dalel">
        <AllDewan />
      </Route>
      <Route path="/business">
        <AllBusiness />
      </Route>

      <Route path="/alshattishop">
        <AllProducts />
      </Route>

      <Route path="/sandoq">
        <Sandooq />
      </Route>

      <Route path="/fazaa">
        <Faz3aList />
      </Route>
      <Route path="/oldfaz3a">
        <OldFaz3a />
      </Route>
      <Route path="/contactus">
        <ContactUs />
      </Route>

      <Route path="/familytree">
        <FamilyTree />
      </Route>
      <Route path="/admin">
        <Signin />
      </Route>
      <Route path="/mathon">
        <Mathon />
      </Route>
      <Route path="/massages">
        <Massages />
      </Route>
      <Route path="/lawyer">
        <Lawyer />
      </Route>
      <Route path="/others">
        <Others />
      </Route>
      <Route path="/doctor">
        <Doctor />
      </Route>
      <Route path="/album">
        <Album />
      </Route>
      <Route path="/catalouge/:albumSlug">
        <DetailALbum />
      </Route>
      <Route path="/products/:productSlug">
        <DetailProduct />
      </Route>
      <Route path="/form">
        <FormPage />
      </Route>

      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};

export default observer(Routes);
