import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { observer } from "mobx-react";

import imageSliderStore from "../stores/imageSliderStore";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const AddFaz3aVedio = () => {
  const classes = useStyles();

  const [faz3a, setFaz3a] = useState({
    VidieoOldFaz3a: true,
    vedioCaption: "",
    vedioTittle: "",
    vedioLink: "",
  });

  const handleChange = (event) => {
    console.log(faz3a);
    setFaz3a({ ...faz3a, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    imageSliderStore.creatImageSlider(faz3a, () => {
      window.location = "/oldfaz3a";
    });
  };

  return (
    <Container flexDirection="rtl" component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          اضافه فيديو
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={faz3a.vedioLink}
            name="vedioLink"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="رابط الفيديو"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={faz3a.vedioTittle}
            name="vedioTittle"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="عنوان الفيديو"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            value={faz3a.vedioCaption}
            name="vedioCaption"
            type="text"
            flexDirection="rtl"
            onChange={handleChange}
            label="اضف تعليق"
          />{" "}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            اضافة
          </Button>
          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default observer(AddFaz3aVedio);
