import { Link } from "react-router-dom";
import { observer } from "mobx-react";
//Buttons
//styles
import { ItemImg, TextUnderImgThePrice, UpdateButtonStyled } from "../styles";
import authStore from "../stores/authStore";
import productStore from "../stores/productStore";
import UpdateButton from "./buttons/UpdateButton";

const OneItemProduct = ({ product }) => {
  return (
    <div>
      <Link to={`/products/${product.slug}`}>
        <ItemImg src={product.image} alt={product.name} />
      </Link>
      <TextUnderImgThePrice>{product.name}</TextUnderImgThePrice>
      <TextUnderImgThePrice>price: {product.price} KD</TextUnderImgThePrice>

      {authStore.user ? (
        <button
          class="btn btn-danger"
          onClick={() => productStore.deleteProduct(product.id)}
        >
          حذف{" "}
        </button>
      ) : (
        ""
      )}
      <div>{authStore.user ? <UpdateButton product={product} /> : ""}</div>
    </div>
  );
};

export default observer(OneItemProduct);
