import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
//store
import authStore from "../stores/authStore";
import albumStore from "../stores/albumStore";
import pictureStore from "../stores/pictureStore";
import { Tabledata } from "../styles";
// import AddCoursesButton from "./buttons/AddCoursesButton";
function Album() {
  const CoursesList = albumStore.albums.map((album) => (
    <tr>
      <td align="center">
        <div class="table-responsive">
          <table class="table table-bordered ">
            <thead
              class="thead-light"
              bg="light"
              expand="lg"
              sticky="top"
              position="fixed"
            >
              <h4 align="center">
                <br></br>
                <Link to={`/catalouge/${album.slugcreator}`}>{album.name}</Link>
              </h4>
            </thead>
          </table>
        </div>
        {authStore.user ? (
          <div>
            <br></br>
            <button
              class="btn btn-danger"
              onClick={() => albumStore.deleteAlbum(album)}
            >
              حذف{" "}
            </button>
          </div>
        ) : (
          ""
        )}
      </td>
    </tr>
  ));
  return (
    <div>
      <div
        style={{
          backgroundColor: "#2B547E",
          color: "white",
          align: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <br></br>
        <h1
          style={{
            backgroundColor: "#2B547E",
            color: "white",
            align: "center",
          }}
        >
          البوم الصور
        </h1>
        <br></br>
      </div>
      {authStore.user ? (
        <Link to="/addalbum">
          <div align="center">
            <button align="center" class="btn btn-success">
              اضافه البوم
            </button>
            <br></br>
          </div>
        </Link>
      ) : (
        ""
      )}

      <div>
        <div class="table-responsive">
          <table class="table table-bordered ">
            <thead bg="light" expand="lg" sticky="top" position="fixed"></thead>
            {CoursesList}
          </table>
        </div>
      </div>
    </div>
  );
}

export default observer(Album);
